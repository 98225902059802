var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { applyDelta, validateValuesAndType } from "./common/core/deltas";
import { Keval, fragmentJson, getPartOrSection } from "./common/core/keval";
import { useCaching, sourceProviderFromHeaders } from "./common/core/fetch";
import { slop } from "./slop";
import "./embed.scss";
import "./fonts.css";
;
;
(_a = window.Kermit) !== null && _a !== void 0 ? _a : (window.Kermit = (function () {
    var options;
    var cache = {};
    var callbacks = {};
    var keval;
    addEventListener("beforeunload", function (event) {
        var e_1, _a;
        if (Object.values(cache).some(function (ce) { return ce.pendingDeltas.length > 0 && ce.unsavedExitMode === "prompt_user"; })) {
            // ask user whether to leave since there are unsaved changes
            event.preventDefault();
            event.returnValue = "";
            try {
                // save all unsaved changes
                for (var _b = __values(Object.entries(cache)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), documentId = _d[0], cacheEntry = _d[1];
                    if (cacheEntry.saveMode === "auto_save" && cacheEntry.pendingDeltas.length > 0) {
                        Kermit.saveDocument(documentId);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    });
    function invokeCallbacks(event) {
        var e_2, _a;
        var _b, _c, _d, _e, _f, _g;
        console.log("Kermit event", event);
        var relevantCallbacks = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(((_b = callbacks[""]) !== null && _b !== void 0 ? _b : [])), false), __read(((_c = callbacks[event.eventType]) !== null && _c !== void 0 ? _c : [])), false), __read(((_d = (event.documentId && callbacks[event.documentId])) !== null && _d !== void 0 ? _d : [])), false), __read(((_e = (event.templateId && callbacks[event.templateId])) !== null && _e !== void 0 ? _e : [])), false), __read(((_f = (event.documentId && callbacks[event.eventType + ":" + event.documentId])) !== null && _f !== void 0 ? _f : [])), false), __read(((_g = (event.templateId && callbacks[event.eventType + ":" + event.templateId])) !== null && _g !== void 0 ? _g : [])), false);
        try {
            for (var relevantCallbacks_1 = __values(relevantCallbacks), relevantCallbacks_1_1 = relevantCallbacks_1.next(); !relevantCallbacks_1_1.done; relevantCallbacks_1_1 = relevantCallbacks_1.next()) {
                var callback = relevantCallbacks_1_1.value;
                try {
                    callback(event);
                }
                catch (e) {
                    console.error("Callback failed", e);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (relevantCallbacks_1_1 && !relevantCallbacks_1_1.done && (_a = relevantCallbacks_1.return)) _a.call(relevantCallbacks_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    var Kermit = {
        addEventListener: function (eventType, documentOrTemplateId, callback) {
            var _a;
            var key = [
                eventType === "*" ? undefined : eventType,
                documentOrTemplateId === "*" ? undefined : documentOrTemplateId,
            ].filter(function (x) { return x; }).join(":");
            ((_a = callbacks[key]) !== null && _a !== void 0 ? _a : (callbacks[key] = [])).push(callback);
            console.log("Registered", key, callbacks);
        },
        removeEventListener: function (eventType, documentOrTemplateId, callback) {
            var key = [
                eventType === "*" ? undefined : eventType,
                documentOrTemplateId === "*" ? undefined : documentOrTemplateId,
            ].filter(function (x) { return x; }).join(":");
            if (callbacks[key]) {
                callbacks[key] = callbacks[key].filter(function (c) { return c !== callback; });
            }
        },
        uninit: function () {
            var e_3, _a;
            try {
                for (var _b = __values(Object.values(cache)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var node = _c.value.node;
                    if (node === null || node === void 0 ? void 0 : node.parentNode) {
                        Kermit.unloadDocument(node.parentNode);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
            options = undefined;
            cache = {};
            callbacks = {};
            keval = undefined;
            console.log("Kermit un-initialized");
        },
        init: function (opts) {
            var e_4, _a;
            var _b;
            if (options) {
                console.warn("Kermit already initialized");
                return;
            }
            // TODO: add type checking?
            if (!opts.company) {
                throw new Error("Kermit init failed; company string is required");
            }
            if (!opts.headers) {
                throw new Error("Kermit init failed; headers obj or function is required");
            }
            options = __assign({ maxUnloadedDocumentsToRetain: 3 }, opts);
            cache = {};
            callbacks = {};
            keval = new Keval(
            // no default source
            {}, 
            // new caching source provider with the user's auth
            useCaching(sourceProviderFromHeaders(options.company, options.headers)), 
            // isStrict
            false);
            var kermitContainer = (_b = document.querySelector("[data-kermit-container]")) !== null && _b !== void 0 ? _b : document.querySelector("body");
            // load all future kermit documents
            new MutationObserver(function (records, observer) {
                var e_5, _a, e_6, _b, e_7, _c, e_8, _d, e_9, _e;
                var _f, _g, _h, _j, _k, _l, _m, _o;
                try {
                    for (var records_1 = __values(records), records_1_1 = records_1.next(); !records_1_1.done; records_1_1 = records_1.next()) {
                        var record = records_1_1.value;
                        if (record.type === "childList") {
                            try {
                                for (var _p = (e_6 = void 0, __values(record.addedNodes)), _q = _p.next(); !_q.done; _q = _p.next()) {
                                    var addedNode = _q.value;
                                    if (addedNode instanceof HTMLElement) {
                                        if (((_f = addedNode.dataset) === null || _f === void 0 ? void 0 : _f.kermitDocument) || ((_g = addedNode.dataset) === null || _g === void 0 ? void 0 : _g.kermitTemplate)) {
                                            window.Kermit.loadDocument(addedNode);
                                        }
                                        else {
                                            try {
                                                for (var _r = (e_7 = void 0, __values(addedNode.querySelectorAll("[data-kermit-document], [data-kermit-template]"))), _s = _r.next(); !_s.done; _s = _r.next()) {
                                                    var documentNode = _s.value;
                                                    window.Kermit.loadDocument(documentNode);
                                                }
                                            }
                                            catch (e_7_1) { e_7 = { error: e_7_1 }; }
                                            finally {
                                                try {
                                                    if (_s && !_s.done && (_c = _r.return)) _c.call(_r);
                                                }
                                                finally { if (e_7) throw e_7.error; }
                                            }
                                        }
                                    }
                                }
                            }
                            catch (e_6_1) { e_6 = { error: e_6_1 }; }
                            finally {
                                try {
                                    if (_q && !_q.done && (_b = _p.return)) _b.call(_p);
                                }
                                finally { if (e_6) throw e_6.error; }
                            }
                            try {
                                for (var _t = (e_8 = void 0, __values(record.removedNodes)), _u = _t.next(); !_u.done; _u = _t.next()) {
                                    var removedNode = _u.value;
                                    if (removedNode instanceof HTMLElement) {
                                        if (((_h = removedNode.dataset) === null || _h === void 0 ? void 0 : _h.kermitDocument) || ((_j = removedNode.dataset) === null || _j === void 0 ? void 0 : _j.kermitTemplate)) {
                                            window.Kermit.unloadDocument(removedNode);
                                        }
                                        else {
                                            try {
                                                for (var _v = (e_9 = void 0, __values(removedNode.querySelectorAll("[data-kermit-document], [data-kermit-template]"))), _w = _v.next(); !_w.done; _w = _v.next()) {
                                                    var documentNode = _w.value;
                                                    window.Kermit.unloadDocument(documentNode);
                                                }
                                            }
                                            catch (e_9_1) { e_9 = { error: e_9_1 }; }
                                            finally {
                                                try {
                                                    if (_w && !_w.done && (_e = _v.return)) _e.call(_v);
                                                }
                                                finally { if (e_9) throw e_9.error; }
                                            }
                                        }
                                    }
                                }
                            }
                            catch (e_8_1) { e_8 = { error: e_8_1 }; }
                            finally {
                                try {
                                    if (_u && !_u.done && (_d = _t.return)) _d.call(_t);
                                }
                                finally { if (e_8) throw e_8.error; }
                            }
                        }
                        else if (record.type === "attributes") {
                            if (record.target instanceof HTMLElement && ["data-kermit-document", "data-kermit-template"].includes(record.attributeName)) {
                                if (record.oldValue) {
                                    window.Kermit.unloadDocument(record.target, record.attributeName == "data-kermit-document" ? record.oldValue : undefined);
                                }
                                // reload document
                                if (((_k = record.target.dataset) === null || _k === void 0 ? void 0 : _k.kermitDocument) || ((_l = record.target.dataset) === null || _l === void 0 ? void 0 : _l.kermitTemplate)) {
                                    window.Kermit.loadDocument(record.target);
                                }
                            }
                            else if (record.target instanceof HTMLElement && ["data-kermit-modes"].includes(record.attributeName)) {
                                if (((_m = record.target.dataset) === null || _m === void 0 ? void 0 : _m.kermitDocument) || ((_o = record.target.dataset) === null || _o === void 0 ? void 0 : _o.kermitTemplate)) {
                                    // modes changed - unload and then reload with the correct modes
                                    window.Kermit.unloadDocument(record.target);
                                    window.Kermit.loadDocument(record.target);
                                }
                            }
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (records_1_1 && !records_1_1.done && (_a = records_1.return)) _a.call(records_1);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
            }).observe(kermitContainer, {
                childList: true,
                subtree: true,
                attributeFilter: ["data-kermit-document", "data-kermit-template", "data-kermit-modes"],
                attributeOldValue: true,
            });
            console.log("Kermit initialized");
            try {
                for (var _c = __values(kermitContainer.querySelectorAll("[data-kermit-document], [data-kermit-template]")), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var documentNode = _d.value;
                    window.Kermit.loadDocument(documentNode);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_4) throw e_4.error; }
            }
        },
        unloadDocument: function (documentNode, documentId) {
            var _a, _b, _c;
            documentId !== null && documentId !== void 0 ? documentId : (documentId = (_a = documentNode.dataset) === null || _a === void 0 ? void 0 : _a.kermitDocument);
            console.log("Unloading", documentId);
            if (!documentId) {
                return;
            }
            if (cache[documentId]) {
                cache[documentId].state = "unloaded";
                if ((_b = cache[documentId].node) === null || _b === void 0 ? void 0 : _b.parentNode) {
                    var parentNode = cache[documentId].node.parentNode;
                    // clear parent node and remove the temporary document id in case just the id changed
                    parentNode.innerHTML = "";
                    if ((_c = parentNode.dataset) === null || _c === void 0 ? void 0 : _c.kermitTemplate) {
                        // remove the documentId so a new one will be generated
                        delete parentNode.dataset.kermitDocument;
                    }
                }
                invokeCallbacks({
                    eventType: "unloaded",
                    documentId: documentId,
                });
                // clean up previously unloaded documents
                var unloadedDocumentIds = Object.entries(cache).filter(function (_a) {
                    var _b = __read(_a, 2), di = _b[0], ce = _b[1];
                    return ce.state === "unloaded";
                }).map(function (_a) {
                    var _b = __read(_a, 2), di = _b[0], ce = _b[1];
                    return di;
                });
                while (unloadedDocumentIds.length > options.maxUnloadedDocumentsToRetain) {
                    // the first will be the oldest
                    var documentId_1 = unloadedDocumentIds.shift();
                    delete cache[documentId_1];
                    invokeCallbacks({
                        eventType: "purged",
                        documentId: documentId_1,
                    });
                }
            }
        },
        loadDocument: function (documentNode) { return __awaiter(void 0, void 0, void 0, function () {
            var documentId, templateId, modes, loadingNode, template, documentHtml, _a, _b, parameters, _c, _d, _e, path, value, err_1;
            var _f, e_10, _g;
            var _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            return __generator(this, function (_u) {
                switch (_u.label) {
                    case 0:
                        documentId = (_h = documentNode.dataset) === null || _h === void 0 ? void 0 : _h.kermitDocument;
                        if (!documentId) {
                            templateId = (_j = documentNode.dataset) === null || _j === void 0 ? void 0 : _j.kermitTemplate;
                            if (templateId) {
                                documentId = ["template", templateId, crypto.randomUUID().slice(30)].join(":");
                                invokeCallbacks({
                                    eventType: "instantiated",
                                    documentId: documentId,
                                    templateId: templateId,
                                });
                                // this will trigger another load call so let that take over
                                documentNode.dataset.kermitDocument = documentId;
                                return [2 /*return*/];
                            }
                            else {
                                console.warn("Missing data-kermit-document or data-kermit-template value on", documentNode);
                                return [2 /*return*/];
                            }
                        }
                        else if (documentId.startsWith("template:")) {
                            templateId = documentId.split(":")[1];
                        }
                        console.log("Loading Kermit document", documentId, cache[documentId]);
                        modes = (_l = (_k = documentNode.dataset) === null || _k === void 0 ? void 0 : _k.kermitModes) !== null && _l !== void 0 ? _l : "view";
                        // reuse the cache if applicable
                        if (((_m = cache[documentId]) === null || _m === void 0 ? void 0 : _m.state) === "unloaded") {
                            // must be unloaded and have the same requested modes
                            if (modes.split(",").sort().join(",") === cache[documentId].modes.sort().join(",")) {
                                console.log("REUSING", cache[documentId]);
                                // already loaded - reuse the existing node
                                documentNode.innerHTML = "";
                                documentNode.appendChild(cache[documentId].node);
                                cache[documentId].state = "loaded";
                                return [2 /*return*/];
                            }
                        }
                        else if (cache[documentId]) {
                            // NOTE: no longer warn the user here - just ignore since many times this is a transient state and not a helpful signal
                            // documentNode.innerHTML = "Loading the same document multiple times currently is not supported";
                            return [2 /*return*/];
                        }
                        if ((_o = options.loading) === null || _o === void 0 ? void 0 : _o.templateSelector) {
                            documentNode.innerHTML = "";
                            template = document.querySelector(options.loading.templateSelector);
                            if (template instanceof HTMLTemplateElement && (template === null || template === void 0 ? void 0 : template.content)) {
                                loadingNode = template.content.cloneNode(true);
                            }
                        }
                        if (loadingNode) {
                            documentNode.innerHTML = "";
                            documentNode.appendChild(loadingNode);
                        }
                        else {
                            documentNode.innerHTML = (_r = (_q = (_p = options.loading) === null || _p === void 0 ? void 0 : _p.html) !== null && _q !== void 0 ? _q : options === null || options === void 0 ? void 0 : options.loading.text) !== null && _r !== void 0 ? _r : "Loading...";
                        }
                        // TODO: optimize re-using templates via structuredClone (with polyfill as needed)
                        // initialize / reset deltas for this document
                        cache[documentId] = {
                            templateId: templateId,
                            saveMode: (templateId || documentNode.dataset.kermitSaveMode === "on_submit") ? "on_submit" : "auto_save",
                            unsavedExitMode: (documentNode.dataset.kermitUnsavedExitMode === "allow") ? "allow" : "prompt_user",
                            modes: modes.split(","),
                            state: "loading",
                            pendingDeltas: [],
                            conditionals: {},
                        };
                        _u.label = 1;
                    case 1:
                        _u.trys.push([1, 4, , 5]);
                        _a = fetch;
                        _b = ["".concat(options.kermitBaseUrl, "/render/company/").concat(options.company, "/documents/").concat(templateId !== null && templateId !== void 0 ? templateId : documentId, ".html?modes=").concat(modes, "&presets=").concat((_s = documentNode.dataset.kermitParameters) !== null && _s !== void 0 ? _s : "")];
                        _f = {
                            method: "GET",
                            mode: "cors",
                            cache: "no-cache"
                        };
                        return [4 /*yield*/, getHeaders()];
                    case 2: return [4 /*yield*/, _a.apply(void 0, _b.concat([(_f.headers = _u.sent(),
                                _f)])).then(function (resp) {
                            if (resp.status >= 400) {
                                console.error("Failed to load", resp);
                                throw new Error("Failed to load");
                            }
                            return resp;
                        }).then(function (resp) { return resp.text(); })];
                    case 3:
                        documentHtml = _u.sent();
                        documentNode.innerHTML = documentHtml;
                        cache[documentId].document = JSON.parse(documentNode.querySelector(".kd-source").innerText);
                        if (templateId) {
                            // the template itself will be locked - allow editing
                            cache[documentId].document.editState = "editable";
                        }
                        // keep a reference in case this is needed again before a page reload
                        // this is specifically for React (and similar setups) which may remove the div but then add it back after
                        // also useful to avoid using querySelector on the top level DOM document which will fail if this was just removed - allows save to finish its operation successfully even if recently unloaded
                        cache[documentId].node = documentNode.querySelector(".kd");
                        cache[documentId].state = "loaded";
                        registerListeners(documentId, documentNode);
                        if (cache[documentId].node.dataset.kdMode === "edit") {
                            resizeAllInputs(documentNode);
                        }
                        parameters = JSON.parse((_t = documentNode.dataset.kermitParameters) !== null && _t !== void 0 ? _t : "{}");
                        try {
                            for (_c = __values(Object.entries(parameters)), _d = _c.next(); !_d.done; _d = _c.next()) {
                                _e = __read(_d.value, 2), path = _e[0], value = _e[1];
                                Kermit.addDelta(documentId, {
                                    delta: "EditPartDelta",
                                    path: path,
                                    mode: "replace",
                                    values: Array.isArray(value) ? value : [value],
                                });
                            }
                        }
                        catch (e_10_1) { e_10 = { error: e_10_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_g = _c.return)) _g.call(_c);
                            }
                            finally { if (e_10) throw e_10.error; }
                        }
                        invokeCallbacks({
                            eventType: "loaded",
                            documentId: documentId
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _u.sent();
                        console.error(err_1);
                        documentNode.innerHTML = "Failed to load";
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
        saveDocument: function (documentId) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, slop(documentId, function () { return __awaiter(void 0, void 0, void 0, function () {
                        var e_11;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (cache[documentId].templateId) {
                                        // templates can't save before submit because the actual document doesn't exist yet
                                        console.log("Skipping auto save of", documentId);
                                        return [2 /*return*/];
                                    }
                                    console.log("Saving Kermit document", documentId);
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, internalSave(documentId)];
                                case 2:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_11 = _a.sent();
                                    // try to save again after the delay
                                    Kermit.saveDocument(documentId);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); }, 
                    // save no more than once every 5 seconds
                    5)];
            });
        }); },
        submitDocument: function (documentId) { return __awaiter(void 0, void 0, void 0, function () {
            var resultNode, doc, errorCount, _a, _b, part, errors, errors_1, errors_1_1, _c, path, validationError, partNode, errorNode, e_12_1, submitButton, templateId, resp, _d, _e, _f;
            var e_12, _g, e_13, _h, _j;
            var _k;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        if (!(!cache[documentId].templateId && cache[documentId].pendingDeltas.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, internalSave(documentId)];
                    case 1:
                        _l.sent();
                        _l.label = 2;
                    case 2:
                        resultNode = cache[documentId].node.querySelector(".kd-submit .kd-result");
                        delete resultNode.dataset.kdError;
                        delete resultNode.dataset.kdSuccess;
                        doc = cache[documentId].document;
                        errorCount = 0;
                        _l.label = 3;
                    case 3:
                        _l.trys.push([3, 8, 9, 10]);
                        _a = __values(doc.parts), _b = _a.next();
                        _l.label = 4;
                    case 4:
                        if (!!_b.done) return [3 /*break*/, 7];
                        part = _b.value;
                        return [4 /*yield*/, validateValuesAndType("", part, {}, { doc: doc, allowedModes: new Set(["view", "edit"]), validationMode: "fullRecursive", keval: keval })];
                    case 5:
                        errors = _l.sent();
                        errorCount += errors.length;
                        try {
                            for (errors_1 = (e_13 = void 0, __values(errors)), errors_1_1 = errors_1.next(); !errors_1_1.done; errors_1_1 = errors_1.next()) {
                                _c = errors_1_1.value, path = _c.path, validationError = _c.validationError;
                                partNode = cache[documentId].node.querySelector("[data-kp-key=\"".concat(path, "\"]"));
                                errorNode = partNode.querySelector(".kp-error");
                                // show error
                                errorNode.dataset.kpError = validationError;
                            }
                        }
                        catch (e_13_1) { e_13 = { error: e_13_1 }; }
                        finally {
                            try {
                                if (errors_1_1 && !errors_1_1.done && (_h = errors_1.return)) _h.call(errors_1);
                            }
                            finally { if (e_13) throw e_13.error; }
                        }
                        _l.label = 6;
                    case 6:
                        _b = _a.next();
                        return [3 /*break*/, 4];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_12_1 = _l.sent();
                        e_12 = { error: e_12_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (_b && !_b.done && (_g = _a.return)) _g.call(_a);
                        }
                        finally { if (e_12) throw e_12.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        if (errorCount > 0) {
                            resultNode.dataset.kdError = "Found " + errorCount + " errors";
                            return [2 /*return*/, false];
                        }
                        submitButton = cache[documentId].node.querySelector(".kd-submit button");
                        submitButton.innerText = "Submitting...";
                        submitButton.disabled = true;
                        templateId = cache[documentId].templateId;
                        _d = fetch;
                        _e = ["".concat(options.kermitBaseUrl, "/api/company/").concat(options.company, "/documents/").concat(templateId !== null && templateId !== void 0 ? templateId : documentId, "/").concat(templateId ? "post" : "submit")];
                        _j = {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache"
                        };
                        _f = [{}];
                        return [4 /*yield*/, getHeaders()];
                    case 11: return [4 /*yield*/, _d.apply(void 0, _e.concat([(_j.headers = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { "Content-Type": "application/json" }]),
                                _j.body = templateId ? JSON.stringify({ displayName: "", deltas: cache[documentId].pendingDeltas }) : "{}",
                                _j)])).then(function (resp) { return resp.json(); })];
                    case 12:
                        resp = _l.sent();
                        // TODO: handle switching to actual documentId
                        if (resp.data) {
                            cache[documentId].document = resp.data;
                            resultNode.dataset.kdSuccess = "Successfully submitted!";
                        }
                        else {
                            resultNode.dataset.kdError = (_k = resp.error) !== null && _k !== void 0 ? _k : "Failed to submit";
                        }
                        submitButton.innerText = "Submit";
                        submitButton.disabled = false;
                        invokeCallbacks({
                            eventType: "submitted",
                            documentId: documentId,
                            templateId: templateId,
                            submittedDocumentId: resp.data.documentId,
                            documentData: fragmentJson(resp.data),
                        });
                        return [2 /*return*/, true];
                }
            });
        }); },
        addDelta: function (documentId, delta) { return __awaiter(void 0, void 0, void 0, function () {
            var doc, modes, error, deltaParams, path, partNode, errorNode, values, rawValueNode, optionNodes, _loop_1, _a, _b, optionNode, checkboxNodes, _loop_2, _c, _d, checkboxNode, selectNodes, _e, _f, selectNode, valueNodes, _loop_3, _g, _h, valueNode, _j, _k, dependentPath, sourcePath, sourcePartNode, newSectionNode, _l, _m, newPartOrSection, _o, _p, newLabel, _q, _r, newInput, replaceIndex, submitButton;
            var e_14, _s, e_15, _t, e_16, _u, e_17, _v, e_18, _w, e_19, _x, e_20, _y, e_21, _z;
            var _0, _1, _2, _3, _4, _5, _6;
            return __generator(this, function (_7) {
                switch (_7.label) {
                    case 0:
                        doc = cache[documentId].document;
                        modes = new Set();
                        modes.add(cache[documentId].node.dataset.kdMode);
                        return [4 /*yield*/, applyDelta(delta, { doc: doc, allowedModes: modes, validationMode: "fullRecursive", keval: keval })];
                    case 1:
                        deltaParams = _7.sent();
                        if (!("errorMessage" in deltaParams)) return [3 /*break*/, 3];
                        error = (_2 = (_1 = (_0 = deltaParams.validationErrors) === null || _0 === void 0 ? void 0 : _0[0]) === null || _1 === void 0 ? void 0 : _1.validationError) !== null && _2 !== void 0 ? _2 : deltaParams.errorMessage;
                        if (!(cache[documentId].document.typeState !== "submitted")) return [3 /*break*/, 3];
                        return [4 /*yield*/, applyDelta(delta, { doc: doc, allowedModes: modes, validationMode: "allowPartialShallow", keval: keval })];
                    case 2:
                        // try again but shallow
                        deltaParams = _7.sent();
                        _7.label = 3;
                    case 3:
                        path = (("errorMessage" in deltaParams || ((_3 = deltaParams.currentPart) !== null && _3 !== void 0 ? _3 : deltaParams.currentSection)) ? delta.path : delta.path.slice(0, delta.path.lastIndexOf(".")));
                        partNode = cache[documentId].node.querySelector("[data-kp-key=\"".concat(path, "\"]"));
                        if (partNode) {
                            errorNode = partNode.querySelector(".kp-error");
                            if (error) {
                                // show error
                                errorNode.dataset.kpError = error;
                            }
                            else {
                                // hide error
                                delete errorNode.dataset.kpError;
                            }
                        }
                        if ("errorMessage" in deltaParams) {
                            console.log("FAILED TO APPLY DELTA", deltaParams);
                            // delta was not applied
                            return [2 /*return*/];
                        }
                        if (delta.delta === "EditPartDelta") {
                            values = ((_5 = (_4 = deltaParams.currentPart) === null || _4 === void 0 ? void 0 : _4.values) !== null && _5 !== void 0 ? _5 : []);
                            rawValueNode = partNode.querySelector(".kpv-raw");
                            if (rawValueNode) {
                                rawValueNode.innerText = values.join(",");
                            }
                            // update empty flag
                            partNode.dataset.kpEmpty = "" + values.every(function (v) { return typeof v === "string" && v.trim().length === 0; });
                            optionNodes = partNode.querySelectorAll(".kt-option");
                            _loop_1 = function (optionNode) {
                                optionNode.dataset.ktOptionSelected = "" + values.filter(function (v) { return v === optionNode.dataset.ktOption; }).length;
                            };
                            try {
                                for (_a = __values(optionNodes), _b = _a.next(); !_b.done; _b = _a.next()) {
                                    optionNode = _b.value;
                                    _loop_1(optionNode);
                                }
                            }
                            catch (e_14_1) { e_14 = { error: e_14_1 }; }
                            finally {
                                try {
                                    if (_b && !_b.done && (_s = _a.return)) _s.call(_a);
                                }
                                finally { if (e_14) throw e_14.error; }
                            }
                            checkboxNodes = partNode.querySelectorAll(".kt-checkbox-option input");
                            _loop_2 = function (checkboxNode) {
                                checkboxNode.checked = values.some(function (v) { return v === checkboxNode.value; });
                            };
                            try {
                                for (_c = __values(checkboxNodes), _d = _c.next(); !_d.done; _d = _c.next()) {
                                    checkboxNode = _d.value;
                                    _loop_2(checkboxNode);
                                }
                            }
                            catch (e_15_1) { e_15 = { error: e_15_1 }; }
                            finally {
                                try {
                                    if (_d && !_d.done && (_t = _c.return)) _t.call(_c);
                                }
                                finally { if (e_15) throw e_15.error; }
                            }
                            selectNodes = partNode.querySelectorAll(".kt-options select");
                            try {
                                for (_e = __values(selectNodes), _f = _e.next(); !_f.done; _f = _e.next()) {
                                    selectNode = _f.value;
                                    selectNode.selectedIndex = 0;
                                    selectNode.value = "" + values[0];
                                }
                            }
                            catch (e_16_1) { e_16 = { error: e_16_1 }; }
                            finally {
                                try {
                                    if (_f && !_f.done && (_u = _e.return)) _u.call(_e);
                                }
                                finally { if (e_16) throw e_16.error; }
                            }
                            valueNodes = partNode.querySelectorAll(".kpv-value");
                            _loop_3 = function (valueNode) {
                                var value = valueNode.dataset.kpValue;
                                if (value && !values.some(function (v) { return v === value; })) {
                                    valueNode.remove();
                                }
                            };
                            try {
                                for (_g = __values(valueNodes), _h = _g.next(); !_h.done; _h = _g.next()) {
                                    valueNode = _h.value;
                                    _loop_3(valueNode);
                                }
                            }
                            catch (e_17_1) { e_17 = { error: e_17_1 }; }
                            finally {
                                try {
                                    if (_h && !_h.done && (_v = _g.return)) _v.call(_g);
                                }
                                finally { if (e_17) throw e_17.error; }
                            }
                            try {
                                for (_j = __values((_6 = cache[documentId].conditionals[delta.path]) !== null && _6 !== void 0 ? _6 : []), _k = _j.next(); !_k.done; _k = _j.next()) {
                                    dependentPath = _k.value;
                                    reevaluateVisibility(documentId, dependentPath);
                                }
                            }
                            catch (e_18_1) { e_18 = { error: e_18_1 }; }
                            finally {
                                try {
                                    if (_k && !_k.done && (_w = _j.return)) _w.call(_j);
                                }
                                finally { if (e_18) throw e_18.error; }
                            }
                        }
                        else if (delta.delta === "RemoveSectionDelta") {
                            partNode.remove();
                        }
                        else if (delta.delta === "AddSectionDelta") {
                            sourcePath = delta.source.path;
                            sourcePartNode = cache[documentId].node.querySelector("[data-kp-key=\"".concat(delta.source.path, "\"]"));
                            newSectionNode = sourcePartNode.cloneNode(true);
                            newSectionNode.dataset.kpKey = newSectionNode.dataset.kpKey.replace(sourcePath, delta.path);
                            try {
                                for (_l = __values(newSectionNode.querySelectorAll("[data-kp-key]")), _m = _l.next(); !_m.done; _m = _l.next()) {
                                    newPartOrSection = _m.value;
                                    newPartOrSection.dataset.kpKey = newPartOrSection.dataset.kpKey.replace(sourcePath, delta.path);
                                }
                            }
                            catch (e_19_1) { e_19 = { error: e_19_1 }; }
                            finally {
                                try {
                                    if (_m && !_m.done && (_x = _l.return)) _x.call(_l);
                                }
                                finally { if (e_19) throw e_19.error; }
                            }
                            try {
                                for (_o = __values(newSectionNode.querySelectorAll("label")), _p = _o.next(); !_p.done; _p = _o.next()) {
                                    newLabel = _p.value;
                                    newLabel.htmlFor = newLabel.htmlFor.replace(new RegExp(":[0-9a-f-]+:" + sourcePath), ":" + documentId + ":" + delta.path);
                                }
                            }
                            catch (e_20_1) { e_20 = { error: e_20_1 }; }
                            finally {
                                try {
                                    if (_p && !_p.done && (_y = _o.return)) _y.call(_o);
                                }
                                finally { if (e_20) throw e_20.error; }
                            }
                            try {
                                for (_q = __values(newSectionNode.querySelectorAll("input")), _r = _q.next(); !_r.done; _r = _q.next()) {
                                    newInput = _r.value;
                                    newInput.id = newInput.id.replace(new RegExp(":[0-9a-f-]+:" + sourcePath), ":" + documentId + ":" + delta.path);
                                }
                            }
                            catch (e_21_1) { e_21 = { error: e_21_1 }; }
                            finally {
                                try {
                                    if (_r && !_r.done && (_z = _q.return)) _z.call(_q);
                                }
                                finally { if (e_21) throw e_21.error; }
                            }
                            partNode.querySelector(".kp-sections").appendChild(newSectionNode);
                            registerListeners(documentId, newSectionNode);
                        }
                        replaceIndex = -1;
                        if (delta.delta === "EditPartDelta" && delta.mode === "replace") {
                            // throw away any previous pending edit deltas for this part as superfluous
                            replaceIndex = cache[documentId].pendingDeltas.findIndex(function (d) { return d.delta === delta.delta && d.path === delta.path; });
                        }
                        console.log("NEW DELTA", delta);
                        if (replaceIndex >= 0) {
                            cache[documentId].pendingDeltas.splice(replaceIndex, 1, delta);
                        }
                        else {
                            cache[documentId].pendingDeltas.push(delta);
                        }
                        if (cache[documentId].saveMode === "auto_save") {
                            submitButton = cache[documentId].node.querySelector(".kd-submit button");
                            submitButton.innerText = "Auto save pending...";
                            submitButton.disabled = true;
                            // kick off a save (eventually)
                            Kermit.saveDocument(documentId);
                        }
                        return [2 /*return*/];
                }
            });
        }); },
    };
    function getHeaders() {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(typeof options.headers == "function")) return [3 /*break*/, 2];
                        return [4 /*yield*/, options.headers()];
                    case 1:
                        headers = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        headers = options.headers;
                        _a.label = 3;
                    case 3: return [2 /*return*/, headers !== null && headers !== void 0 ? headers : {}];
                }
            });
        });
    }
    function internalSave(documentId) {
        return __awaiter(this, void 0, void 0, function () {
            var submitButton, resultNode, deltas, templateId, submissionId, resp, _a, _b, _c, e_22, resp, _d, _e, _f, e_23;
            var _g, _h, _j, _k;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        submitButton = cache[documentId].node.querySelector(".kd-submit button");
                        resultNode = cache[documentId].node.querySelector(".kd-submit .kd-result");
                        delete resultNode.dataset.kdError;
                        delete resultNode.dataset.kdSuccess;
                        deltas = cache[documentId].pendingDeltas;
                        cache[documentId].pendingDeltas = [];
                        if (deltas.length === 0) {
                            // this shouldn't happen right?
                            console.warn("Empty save detected");
                            submitButton.innerText = "Submit";
                            submitButton.disabled = false;
                            return [2 /*return*/];
                        }
                        else {
                            submitButton.innerText = "Saving...";
                            submitButton.disabled = true;
                        }
                        if (!documentId.startsWith("template:")) return [3 /*break*/, 5];
                        templateId = documentId.split(":")[1];
                        submissionId = documentId.split(":")[2];
                        _l.label = 1;
                    case 1:
                        _l.trys.push([1, 4, , 5]);
                        _a = fetch;
                        _b = ["".concat(options.kermitBaseUrl, "/api/company/").concat(options.company, "/documents/").concat(templateId, "/post")];
                        _g = {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache"
                        };
                        _c = [{}];
                        return [4 /*yield*/, getHeaders()];
                    case 2: return [4 /*yield*/, _a.apply(void 0, _b.concat([(_g.headers = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_l.sent())])), { "Content-Type": "application/json" }]),
                                _g.body = JSON.stringify({
                                    deltas: deltas,
                                }),
                                _g)])).then(function (resp) {
                            if (resp.status >= 200 && resp.status < 300) {
                                return resp;
                            }
                            console.error("Failed to save", resp);
                            throw new Error("Failed to save");
                        }).then(function (resp) { return resp.json(); })];
                    case 3:
                        resp = _l.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_22 = _l.sent();
                        // add the deltas back in before any subsequent deltas
                        // slop ensures only one invocation at a time so this will preserve the order even if a failure occurs
                        (_h = cache[documentId].pendingDeltas).splice.apply(_h, __spreadArray([0, 0], __read(deltas), false));
                        resultNode.dataset.kdError = "Errors found";
                        console.error("Failed to save", e_22);
                        throw e_22;
                    case 5:
                        _l.trys.push([5, 8, , 9]);
                        _d = fetch;
                        _e = ["".concat(options.kermitBaseUrl, "/api/company/").concat(options.company, "/documents/").concat(documentId, "/apply-deltas")];
                        _j = {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache"
                        };
                        _f = [{}];
                        return [4 /*yield*/, getHeaders()];
                    case 6: return [4 /*yield*/, _d.apply(void 0, _e.concat([(_j.headers = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { "Content-Type": "application/json" }]),
                                _j.body = JSON.stringify({
                                    deltas: deltas,
                                }),
                                _j)])).then(function (resp) {
                            if (resp.status >= 200 && resp.status < 300) {
                                return resp;
                            }
                            console.error("Failed to save", resp);
                            throw new Error("Failed to save");
                        }).then(function (resp) { return resp.json(); })];
                    case 7:
                        resp = _l.sent();
                        if (resp.data) {
                            cache[documentId].document = resp.data;
                            resultNode.dataset.kdSuccess = "Successfully saved!";
                        }
                        else {
                            resultNode.dataset.kdError = "Errors found";
                        }
                        if (cache[documentId].pendingDeltas.length === 0) {
                            // enable submit once all deltas have been saved
                            submitButton.innerText = "Submit";
                            submitButton.disabled = false;
                        }
                        invokeCallbacks({
                            eventType: "saved",
                            documentId: documentId
                        });
                        return [3 /*break*/, 9];
                    case 8:
                        e_23 = _l.sent();
                        // add the deltas back in before any subsequent deltas
                        // slop ensures only one invocation at a time so this will preserve the order even if a failure occurs
                        (_k = cache[documentId].pendingDeltas).splice.apply(_k, __spreadArray([0, 0], __read(deltas), false));
                        resultNode.dataset.kdError = "Errors found";
                        console.error("Failed to save", e_23);
                        throw e_23;
                    case 9: return [2 /*return*/];
                }
            });
        });
    }
    function registerListeners(documentId, documentNode) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, control, _c, _d, submit, _loop_4, _e, _f, breakButton, _loop_5, _g, _h, input, _loop_6, _j, _k, removeButton, _loop_7, _l, _m, addButton, _loop_8, _o, _p, removeButton, _loop_9, _q, _r, choice, _loop_10, _s, _t, choice, _loop_11, _u, _v, choice, _loop_12, _w, _x, fileDropArea, _y, _z, conditionalPart;
            var e_24, _0, e_25, _1, e_26, _2, e_27, _3, e_28, _4, e_29, _5, e_30, _6, e_31, _7, e_32, _8, e_33, _9, e_34, _10, e_35, _11;
            return __generator(this, function (_12) {
                try {
                    // load editor functionality
                    for (_a = __values(documentNode.querySelectorAll("button[data-kd-mode-control]")), _b = _a.next(); !_b.done; _b = _a.next()) {
                        control = _b.value;
                        control.addEventListener("click", handleModeControl(documentId));
                    }
                }
                catch (e_24_1) { e_24 = { error: e_24_1 }; }
                finally {
                    try {
                        if (_b && !_b.done && (_0 = _a.return)) _0.call(_a);
                    }
                    finally { if (e_24) throw e_24.error; }
                }
                try {
                    for (_c = __values(documentNode.querySelectorAll(".kd-submit button")), _d = _c.next(); !_d.done; _d = _c.next()) {
                        submit = _d.value;
                        submit.addEventListener("click", function () { return Kermit.submitDocument(documentId); });
                    }
                }
                catch (e_25_1) { e_25 = { error: e_25_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_1 = _c.return)) _1.call(_c);
                    }
                    finally { if (e_25) throw e_25.error; }
                }
                _loop_4 = function (breakButton) {
                    var breakNode = breakButton.closest(".kp");
                    breakButton.addEventListener("click", function (e) {
                        breakNode.nextSibling.scrollIntoView();
                    });
                };
                try {
                    // major breaks
                    for (_e = __values(documentNode.querySelectorAll(".kp-next button")), _f = _e.next(); !_f.done; _f = _e.next()) {
                        breakButton = _f.value;
                        _loop_4(breakButton);
                    }
                }
                catch (e_26_1) { e_26 = { error: e_26_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_2 = _e.return)) _2.call(_e);
                    }
                    finally { if (e_26) throw e_26.error; }
                }
                _loop_5 = function (input) {
                    var partNode = input.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    input.addEventListener("input", function (event) {
                        if (event.target instanceof HTMLElement) {
                            // resize textarea automatically
                            event.target.style.height = "1px";
                            event.target.style.height = event.target.scrollHeight + "px";
                        }
                    });
                    ["change", "blur"].forEach(function (eventType) { return input.addEventListener(eventType, function (e) {
                        var value = e.target.value.trim();
                        Kermit.addDelta(documentId, {
                            delta: "EditPartDelta",
                            path: partPath,
                            mode: "replace",
                            values: value ? [value] : [],
                        });
                    }); });
                };
                try {
                    // normal inputs
                    for (_g = __values(documentNode.querySelectorAll(".kpv-input .kpv-input-box")), _h = _g.next(); !_h.done; _h = _g.next()) {
                        input = _h.value;
                        _loop_5(input);
                    }
                }
                catch (e_27_1) { e_27 = { error: e_27_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_3 = _g.return)) _3.call(_g);
                    }
                    finally { if (e_27) throw e_27.error; }
                }
                _loop_6 = function (removeButton) {
                    var sectionNode = removeButton.closest(".kp-section");
                    var sectionPath = sectionNode.dataset.kpKey;
                    removeButton.addEventListener("click", function (e) {
                        Kermit.addDelta(documentId, {
                            delta: "RemoveSectionDelta",
                            path: sectionPath,
                        });
                    });
                };
                try {
                    // remove section
                    for (_j = __values(documentNode.querySelectorAll(".ks-remove")), _k = _j.next(); !_k.done; _k = _j.next()) {
                        removeButton = _k.value;
                        _loop_6(removeButton);
                    }
                }
                catch (e_28_1) { e_28 = { error: e_28_1 }; }
                finally {
                    try {
                        if (_k && !_k.done && (_4 = _j.return)) _4.call(_j);
                    }
                    finally { if (e_28) throw e_28.error; }
                }
                _loop_7 = function (addButton) {
                    var partNode = addButton.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    addButton.addEventListener("click", function (e) {
                        Kermit.addDelta(documentId, {
                            delta: "AddSectionDelta",
                            path: [partPath, crypto.randomUUID()].join("."),
                            source: {
                                path: addButton.dataset.ksSourcePath,
                            },
                        });
                    });
                };
                try {
                    // add section
                    for (_l = __values(documentNode.querySelectorAll(".ks-add")), _m = _l.next(); !_m.done; _m = _l.next()) {
                        addButton = _m.value;
                        _loop_7(addButton);
                    }
                }
                catch (e_29_1) { e_29 = { error: e_29_1 }; }
                finally {
                    try {
                        if (_m && !_m.done && (_5 = _l.return)) _5.call(_l);
                    }
                    finally { if (e_29) throw e_29.error; }
                }
                _loop_8 = function (removeButton) {
                    var partNode = removeButton.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    var valueNode = removeButton.closest(".kpv-value");
                    var value = valueNode.dataset.kpValue;
                    removeButton.addEventListener("click", function (e) {
                        Kermit.addDelta(documentId, {
                            delta: "EditPartDelta",
                            path: partPath,
                            mode: "remove",
                            values: [value],
                        });
                    });
                };
                try {
                    // remove values
                    for (_o = __values(documentNode.querySelectorAll(".kpv-value-remove")), _p = _o.next(); !_p.done; _p = _o.next()) {
                        removeButton = _p.value;
                        _loop_8(removeButton);
                    }
                }
                catch (e_30_1) { e_30 = { error: e_30_1 }; }
                finally {
                    try {
                        if (_p && !_p.done && (_6 = _o.return)) _6.call(_o);
                    }
                    finally { if (e_30) throw e_30.error; }
                }
                _loop_9 = function (choice) {
                    var partNode = choice.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    choice.addEventListener("change", function (e) {
                        if (e.target instanceof HTMLSelectElement) {
                            Kermit.addDelta(documentId, {
                                delta: "EditPartDelta",
                                path: partPath,
                                mode: "replace",
                                values: (e.target.value ? [e.target.value] : []),
                            });
                        }
                    });
                };
                try {
                    // standard selects
                    for (_q = __values(documentNode.querySelectorAll(".kt-options select")), _r = _q.next(); !_r.done; _r = _q.next()) {
                        choice = _r.value;
                        _loop_9(choice);
                    }
                }
                catch (e_31_1) { e_31 = { error: e_31_1 }; }
                finally {
                    try {
                        if (_r && !_r.done && (_7 = _q.return)) _7.call(_q);
                    }
                    finally { if (e_31) throw e_31.error; }
                }
                _loop_10 = function (choice) {
                    var partNode = choice.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    // TODO: reference cache[documentId].document instead
                    var allowMultiple = partNode.querySelector(".kpv").dataset.ktAllowmultiple !== "false";
                    choice.addEventListener("change", function (e) {
                        if (e.target instanceof HTMLInputElement) {
                            var selected = e.target.checked;
                            Kermit.addDelta(documentId, {
                                delta: "EditPartDelta",
                                path: partPath,
                                mode: !selected ? "remove" : allowMultiple ? "append" : "replace",
                                values: [e.target.value],
                            });
                        }
                    });
                };
                try {
                    // standard check boxes
                    for (_s = __values(documentNode.querySelectorAll(".kt-checkbox-option input")), _t = _s.next(); !_t.done; _t = _s.next()) {
                        choice = _t.value;
                        _loop_10(choice);
                    }
                }
                catch (e_32_1) { e_32 = { error: e_32_1 }; }
                finally {
                    try {
                        if (_t && !_t.done && (_8 = _s.return)) _8.call(_s);
                    }
                    finally { if (e_32) throw e_32.error; }
                }
                _loop_11 = function (choice) {
                    var partNode = choice.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    // TODO: reference cache[documentId].document instead
                    var allowMultiple = partNode.querySelector(".kpv").dataset.ktAllowmultiple !== "false";
                    var choiceValue = choice.dataset.ktOption;
                    choice.addEventListener("click", function (e) {
                        if (e.target instanceof HTMLElement) {
                            var selected = e.target.closest(".kt-option").dataset.ktOptionSelected;
                            Kermit.addDelta(documentId, {
                                delta: "EditPartDelta",
                                path: partPath,
                                mode: selected !== "0" ? "remove" : allowMultiple ? "append" : "replace",
                                values: [choiceValue],
                            });
                        }
                    });
                };
                try {
                    // legacy check boxes
                    for (_u = __values(documentNode.querySelectorAll(".kt-option")), _v = _u.next(); !_v.done; _v = _u.next()) {
                        choice = _v.value;
                        _loop_11(choice);
                    }
                }
                catch (e_33_1) { e_33 = { error: e_33_1 }; }
                finally {
                    try {
                        if (_v && !_v.done && (_9 = _u.return)) _9.call(_u);
                    }
                    finally { if (e_33) throw e_33.error; }
                }
                _loop_12 = function (fileDropArea) {
                    var e_36, _13;
                    try {
                        for (var _14 = (e_36 = void 0, __values(["dragenter", "dragover", "dragleave", "drop"])), _15 = _14.next(); !_15.done; _15 = _14.next()) {
                            var eventName = _15.value;
                            fileDropArea.addEventListener(eventName, handleFileDropping(documentId), false);
                        }
                    }
                    catch (e_36_1) { e_36 = { error: e_36_1 }; }
                    finally {
                        try {
                            if (_15 && !_15.done && (_13 = _14.return)) _13.call(_14);
                        }
                        finally { if (e_36) throw e_36.error; }
                    }
                    var input = fileDropArea.querySelector("input[type='file']");
                    var partNode = fileDropArea.closest(".kp");
                    var partPath = partNode.dataset.kpKey;
                    var valuesNode = partNode.querySelector(".kpv-values");
                    input.addEventListener("change", function (e) { return handleFileDrop(e.target.files, documentId, partPath, valuesNode); });
                };
                try {
                    // file inputs
                    for (_w = __values(documentNode.querySelectorAll(".kpv-image-input, .kpv-file-input")), _x = _w.next(); !_x.done; _x = _w.next()) {
                        fileDropArea = _x.value;
                        _loop_12(fileDropArea);
                    }
                }
                catch (e_34_1) { e_34 = { error: e_34_1 }; }
                finally {
                    try {
                        if (_x && !_x.done && (_10 = _w.return)) _10.call(_w);
                    }
                    finally { if (e_34) throw e_34.error; }
                }
                try {
                    // register conditional inputs
                    for (_y = __values(documentNode.querySelectorAll(".kp[data-kp-visibility-conditions]")), _z = _y.next(); !_z.done; _z = _y.next()) {
                        conditionalPart = _z.value;
                        registerConditionalPart(documentId, conditionalPart);
                    }
                }
                catch (e_35_1) { e_35 = { error: e_35_1 }; }
                finally {
                    try {
                        if (_z && !_z.done && (_11 = _y.return)) _11.call(_y);
                    }
                    finally { if (e_35) throw e_35.error; }
                }
                return [2 /*return*/];
            });
        });
    }
    function registerConditionalPart(documentId, conditionalPart) {
        var _a, _b;
        var _c;
        var conditionals = ((_a = (_c = cache[documentId]).conditionals) !== null && _a !== void 0 ? _a : (_c.conditionals = {}));
        var path = conditionalPart.dataset.kpKey;
        var part = getPartOrSection(cache[documentId].document, path);
        var conditions = (_b = part.visibilityConditions) !== null && _b !== void 0 ? _b : [];
        var sourcePaths = conditions.flat(Infinity).filter(function (c) { return c.startsWith("."); }).map(function (c) { return c.slice(1); });
        sourcePaths.forEach(function (sp) { var _a; return ((_a = conditionals[sp]) !== null && _a !== void 0 ? _a : (conditionals[sp] = [])).includes(sp) || conditionals[sp].push(path); });
        reevaluateVisibility(documentId, path);
    }
    function reevaluateVisibility(documentId, path) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, conditions, conditionalPart, result, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        doc = cache[documentId].document;
                        conditions = getPartOrSection(doc, path).visibilityConditions;
                        conditionalPart = cache[documentId].node.querySelector("[data-kp-key=\"".concat(path, "\"]"));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, keval({ kexpr: conditions }, doc)];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        result = false;
                        console.error("Failed to evaluate", conditions, fragmentJson(doc), err_2);
                        return [3 /*break*/, 4];
                    case 4:
                        console.log("KEVAL", conditions, "=>", result);
                        if (result) {
                            console.log("show", path);
                            // show part
                            conditionalPart.classList.remove("kp-cond-hidden");
                        }
                        else {
                            console.log("hide", path);
                            // hide part
                            conditionalPart.classList.add("kp-cond-hidden");
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleModeControl(documentId) {
        return function (event) {
            var target = event.target;
            var modeButton = target.closest(".kd-control");
            var mode = modeButton.dataset.kdModeControl;
            var currentModeButton = modeButton.parentNode.querySelector("[data-kd-selected-mode]");
            var documentNode = target.closest(".kd");
            if (currentModeButton) {
                delete currentModeButton.dataset.kdSelectedMode;
            }
            modeButton.dataset.kdSelectedMode = "true";
            documentNode.dataset.kdMode = mode;
            if (mode === "edit") {
                resizeAllInputs(documentNode);
            }
        };
    }
    function resizeAllInputs(documentNode) {
        var e_37, _a;
        try {
            for (var _b = __values(documentNode.querySelectorAll(".kpv-input .kpv-input-box")), _c = _b.next(); !_c.done; _c = _b.next()) {
                var input = _c.value;
                if (input instanceof HTMLElement && !input.style.height) {
                    // trigger the resize event
                    // this seems to be work better than directly updating the input style here for some reason
                    input.dispatchEvent(new Event('input'));
                }
            }
        }
        catch (e_37_1) { e_37 = { error: e_37_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_37) throw e_37.error; }
        }
    }
    function handleFileDropping(documentId) {
        return function (event) {
            var fileDropArea = event.target.closest(".kpv-input");
            var dropping = ["dragenter", "dragover"].includes(event.type);
            if (dropping) {
                fileDropArea.classList.add("dropping");
            }
            else {
                fileDropArea.classList.remove("dropping");
            }
            if (event.type === "drop") {
                var partNode = fileDropArea.closest(".kp");
                var partPath = partNode.dataset.kpKey;
                var valuesNode = partNode.querySelector(".kpv-values");
                handleFileDrop(event.dataTransfer.files, documentId, partPath, valuesNode);
            }
            event.preventDefault();
            event.stopPropagation();
        };
    }
    ;
    function handleFileDrop(files, documentId, partPath, valuesNode) {
        return __awaiter(this, void 0, void 0, function () {
            var isImage, loadingNodes, files_1, files_1_1, file, newPartValueNode, presignResp, _a, _b, _c, promises, _loop_13, i;
            var e_38, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        console.log("DROPPED", {
                            files: files,
                            documentId: documentId,
                            partPath: partPath,
                        });
                        isImage = (valuesNode.closest(".kp").dataset.kt === "image");
                        loadingNodes = [];
                        try {
                            for (files_1 = __values(files), files_1_1 = files_1.next(); !files_1_1.done; files_1_1 = files_1.next()) {
                                file = files_1_1.value;
                                newPartValueNode = valuesNode.querySelector("template").content.firstElementChild.cloneNode(true);
                                delete newPartValueNode.dataset.kpValue;
                                loadingNodes.push(valuesNode.appendChild(newPartValueNode));
                            }
                        }
                        catch (e_38_1) { e_38 = { error: e_38_1 }; }
                        finally {
                            try {
                                if (files_1_1 && !files_1_1.done && (_d = files_1.return)) _d.call(files_1);
                            }
                            finally { if (e_38) throw e_38.error; }
                        }
                        console.log(loadingNodes);
                        _a = fetch;
                        _b = ["".concat(options.kermitBaseUrl, "/api/company/").concat(options.company, "/documents/").concat(documentId, "/upload")];
                        _e = {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache"
                        };
                        _c = [{}];
                        return [4 /*yield*/, getHeaders()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([(_e.headers = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { "Content-Type": "application/json" }]),
                                _e.body = JSON.stringify({
                                    uploads: __spreadArray([], __read(files), false).map(function (file) { return ({
                                        partPath: partPath,
                                        fileName: file.name,
                                        fileExtension: "." + file.type.split("/").pop(),
                                    }); }),
                                }),
                                _e)])).then(function (resp) {
                            if (resp.status >= 400 && resp.status < 600) {
                                console.error("Failed to load", resp);
                                throw new Error("Failed to load");
                            }
                            return resp;
                        }).then(function (resp) { return resp.json(); })];
                    case 2:
                        presignResp = _f.sent();
                        promises = [];
                        _loop_13 = function (i) {
                            var e_39, _g;
                            var _h = presignResp.data.presignedPosts[i], url = _h.url, fields = _h.fields;
                            var file = files[i];
                            // save file
                            var formData = new FormData();
                            try {
                                for (var _j = (e_39 = void 0, __values(Object.entries(fields))), _k = _j.next(); !_k.done; _k = _j.next()) {
                                    var _l = __read(_k.value, 2), key = _l[0], value = _l[1];
                                    formData.append(key, value);
                                }
                            }
                            catch (e_39_1) { e_39 = { error: e_39_1 }; }
                            finally {
                                try {
                                    if (_k && !_k.done && (_g = _j.return)) _g.call(_j);
                                }
                                finally { if (e_39) throw e_39.error; }
                            }
                            formData.append('file', file);
                            promises.push(fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                body: formData
                            }).then(function (resp) {
                                if (resp.status >= 200 && resp.status < 300) {
                                    // success; remove spinner
                                    var spinner = loadingNodes[i].querySelector(".k-spinner");
                                    spinner.parentNode.removeChild(spinner);
                                    // const imageId = fields["x-amz-meta-image-id"];
                                    var newPartValueNode = loadingNodes[i];
                                    newPartValueNode.dataset.kpValue = url + fields.key;
                                    if (!isImage) {
                                        newPartValueNode.querySelector("a").href = url + fields.key;
                                        newPartValueNode.querySelector("a").innerText = fields.key.split("/").pop().slice(37);
                                    }
                                    registerListeners(documentId, newPartValueNode);
                                    Kermit.addDelta(documentId, {
                                        delta: "EditPartDelta",
                                        path: partPath,
                                        mode: "append",
                                        values: [url + fields.key],
                                    });
                                }
                                else {
                                    console.log("S3 upload POST failed", resp);
                                    // failure; remove upload and / or show error
                                    var loadingNode = loadingNodes[i];
                                    loadingNode.parentNode.removeChild(loadingNode);
                                }
                            }));
                            if (isImage) {
                                // preview image
                                var reader_1 = new FileReader();
                                reader_1.readAsDataURL(file);
                                reader_1.onloadend = function () {
                                    // set image source
                                    var newPartValueNode = loadingNodes[i];
                                    newPartValueNode.querySelector("img").src = reader_1.result;
                                };
                            }
                        };
                        for (i = 0; i < files.length; i++) {
                            _loop_13(i);
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 3:
                        _f.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    console.log("Kermit Loaded");
    return Kermit;
})());
