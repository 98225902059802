var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isKExpression } from "./keval";
export var internalApplyDeltas = function (documentId, document, parts, deltas, allowedModes, keval) { return __awaiter(void 0, void 0, void 0, function () {
    var deltas_1, deltas_1_1, delta, result, e_1_1;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, 6, 7]);
                deltas_1 = __values(deltas), deltas_1_1 = deltas_1.next();
                _b.label = 1;
            case 1:
                if (!!deltas_1_1.done) return [3 /*break*/, 4];
                delta = deltas_1_1.value;
                return [4 /*yield*/, applyDelta(delta, {
                        doc: document,
                        allowedModes: allowedModes,
                        validationMode: document.typeState === "submitted"
                            ? "fullRecursive"
                            : "allowPartialShallow",
                        keval: keval,
                    })];
            case 2:
                result = _b.sent();
                if ("errorMessage" in result) {
                    return [2 /*return*/, {
                            error: "invalid delta (".concat(result.errorMessage, "): ").concat(JSON.stringify(delta)),
                        }];
                }
                _b.label = 3;
            case 3:
                deltas_1_1 = deltas_1.next();
                return [3 /*break*/, 1];
            case 4: return [3 /*break*/, 7];
            case 5:
                e_1_1 = _b.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 7];
            case 6:
                try {
                    if (deltas_1_1 && !deltas_1_1.done && (_a = deltas_1.return)) _a.call(deltas_1);
                }
                finally { if (e_1) throw e_1.error; }
                return [7 /*endfinally*/];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var applyDelta = function (delta, context) { return __awaiter(void 0, void 0, void 0, function () {
    var deltaFunc, deltaParams;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                deltaFunc = deltaFunctions[delta.delta];
                if (!deltaFunc) {
                    throw new Error("Unknown delta");
                }
                return [4 /*yield*/, findDeltaParameters(context.doc.parts, delta, context)];
            case 1:
                deltaParams = _a.sent();
                if ("errorMessage" in deltaParams) {
                    return [2 /*return*/, deltaParams];
                }
                deltaFunc(deltaParams, delta);
                return [2 /*return*/, deltaParams];
        }
    });
}); };
var deltaFunctions = {
    AddPartDelta: function (deltaParams, delta) { return __awaiter(void 0, void 0, void 0, function () {
        var subList, subIndex, partKey;
        return __generator(this, function (_a) {
            subList = deltaParams.subList, subIndex = deltaParams.subIndex, partKey = deltaParams.partKey;
            // remove fields which should be ignored and then copy the rest of the values
            delete delta.delta;
            delete delta.path;
            subList.splice(subIndex, 0, __assign(__assign({ 
                // default values
                values: [], typeConditions: {}, editState: "open", visibility: "public" }, delta), { partKey: partKey, source: null }));
            return [2 /*return*/];
        });
    }); },
    AddSectionDelta: function (deltaParams, delta) {
        var _a, _b, _c, _d;
        var subList = deltaParams.subList, subIndex = deltaParams.subIndex, partKey = deltaParams.partKey, sourceSection = deltaParams.sourceSection;
        subList.splice(subIndex, 0, {
            sectionKey: partKey,
            labelLocalizationKey: (_b = (_a = delta.labelLocalizationKey) !== null && _a !== void 0 ? _a : sourceSection === null || sourceSection === void 0 ? void 0 : sourceSection.labelLocalizationKey) !== null && _b !== void 0 ? _b : "",
            // dynamo doesn't like undefined
            source: (_c = delta.source) !== null && _c !== void 0 ? _c : null,
            parts: structuredClone((_d = sourceSection === null || sourceSection === void 0 ? void 0 : sourceSection.parts) !== null && _d !== void 0 ? _d : []),
        });
    },
    RemoveSectionDelta: function (deltaParams, delta) {
        var subList = deltaParams.subList, subIndex = deltaParams.subIndex;
        // remove from sections
        subList.splice(subIndex, 1);
    },
    CopyPartDelta: function (doc, deltaParams, delta) {
        var subList = deltaParams.subList, subIndex = deltaParams.subIndex, partKey = deltaParams.partKey, sourcePart = deltaParams.sourcePart;
        subList.splice(subIndex, 0, __assign(__assign({}, structuredClone(sourcePart)), { partKey: partKey, source: delta.source }));
    },
    EditPartDelta: function (deltaParams, delta) {
        var currentPart = deltaParams.currentPart;
        currentPart.values = updatedValues(currentPart, delta);
    },
    LockPartDelta: function (deltaParams, delta) {
        var currentPart = deltaParams.currentPart;
        currentPart.editState = delta.editState;
    },
    ModifyPartDelta: function (deltaParams, delta) {
        var currentPart = deltaParams.currentPart;
        var supportedFields = [
            "values",
            "valueType",
            "typeConditions",
            "labelLocalizationKey",
            "placeholderLocalizationKey",
            "hintLocalizationKey",
            "displayClass",
            "visibility",
            "visibilityConditions",
            "principals",
        ];
        Object.keys(delta).forEach(function (field) {
            if (supportedFields.includes(field)) {
                currentPart[field] = delta[field];
            }
        });
    },
    MovePartDelta: function (deltaParams, delta) {
        var subList = deltaParams.subList, subIndex = deltaParams.subIndex, currentPart = deltaParams.currentPart;
        var targetIndex = subIndex + delta.offset;
        // use subList.length - 1 since the subList will be one shorter after we remove the currentPart
        var canInsert = targetIndex >= 0 && targetIndex <= subList.length - 1;
        if (!canInsert) {
            throw new Error("Move failed: offset invalid");
        }
        // remove the part
        subList.splice(subIndex, 1);
        // and reinsert it at the new index
        subList.splice(targetIndex, 0, currentPart);
    },
    RepathPartDelta: function (deltaParams, delta) {
        // TODO: implement as an Remove + Add
        throw new Error("Not yet supported");
    },
    RemovePartDelta: function (deltaParams, delta) {
        var subList = deltaParams.subList, subIndex = deltaParams.subIndex;
        // remove from parts
        subList.splice(subIndex, 1);
    },
};
var supportedFileTypes = {
    image: [".jpeg", ".jpg", ".png"],
    file: [".pdf"],
    file_or_image: [".jpeg", ".jpg", ".png", ".pdf"],
};
function isSectionValue(obj) {
    return (obj === null || obj === void 0 ? void 0 : obj.sectionKey) !== undefined;
}
var updatedValues = function (currentPart, delta // DocumentDelta or empty object
) {
    var e_2, _a;
    var _b, _c;
    if (delta.delta === "EditPartDelta" && !isKExpression(delta.values)) {
        if (delta.mode === "append") {
            return __spreadArray(__spreadArray([], __read(currentPart.values), false), __read(delta.values), false);
        }
        else if (delta.mode === "remove") {
            var values = __spreadArray([], __read(currentPart.values), false);
            try {
                // remove one instance of each value
                for (var _d = __values(delta.values), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var toRemove = _e.value;
                    var index = values.indexOf(toRemove);
                    if (index >= 0) {
                        values.splice(index, 1);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return values;
        }
        return delta.values;
    }
    return (_c = (_b = delta.values) !== null && _b !== void 0 ? _b : currentPart === null || currentPart === void 0 ? void 0 : currentPart.values) !== null && _c !== void 0 ? _c : [];
};
export var deltaAllowed = function (delta, applicableEditStates, allowedModes) {
    // these are allowed in any state + mode
    if (["GetPartDelta", "GetSectionDelta"].includes(delta)) {
        return;
    }
    // these are allowed in non-locked state + edit mode
    if (["AttachFileDelta", "EditPartDelta"].includes(delta)) {
        if (applicableEditStates.some(function (aes) { return aes === "locked"; }) ||
            !allowedModes.has("edit")) {
            return "not editable";
        }
        return;
    }
    // allowed in build mode + any state
    if (delta === "LockPartDelta") {
        if (!allowedModes.has("build")) {
            return "not allowed to change editState";
        }
        return;
    }
    // allowed in build mode only if open
    if (!allowedModes.has("build") &&
        !applicableEditStates.every(function (aes) { return aes === "open"; })) {
        return "not open to modification";
    }
    return;
};
export var findDeltaParameters = function (parts, delta, context) { return __awaiter(void 0, void 0, void 0, function () {
    var subList, ancestorParts, pathParts, subIndex, parentPart, inSectionValueList, _loop_1, pathPartIndex, state_1, currentSection, currentPart, authError, validationErrors, validationErrors, sourcePart, sourceSection, source, _a, shadowDelta, shadowParams;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (context.doc.editState === "locked") {
                    return [2 /*return*/, { errorMessage: "locked" }];
                }
                subList = parts;
                ancestorParts = [];
                pathParts = delta.path.split(".");
                subIndex = -1;
                parentPart = context.doc;
                inSectionValueList = false;
                _loop_1 = function (pathPartIndex) {
                    // find the next part in the path
                    subIndex = subList.findIndex(function (p) { var _a; return ((_a = p.partKey) !== null && _a !== void 0 ? _a : p.sectionKey) === pathParts[pathPartIndex]; });
                    // descend to the next inner level
                    if (pathPartIndex < pathParts.length - 1) {
                        parentPart = inSectionValueList
                            ? parentPart
                            : subList[subIndex];
                        if (parentPart && parentPart.valueType !== "section") {
                            return { value: { errorMessage: "not found" } };
                        }
                        else if (parentPart.editState === "locked" &&
                            !["GetPartDelta", "GetSectionDelta"].includes(delta.delta)) {
                            return { value: { errorMessage: "locked" } };
                        }
                        else if (!inSectionValueList) {
                            ancestorParts.push(parentPart);
                            subList = parentPart.values;
                            inSectionValueList = true;
                        }
                        else if (!subList[subIndex]) {
                            return { value: { errorMessage: "not found" } };
                        }
                        else {
                            subList = subList[subIndex].parts;
                            inSectionValueList = false;
                        }
                    }
                };
                for (pathPartIndex = 0; pathPartIndex < pathParts.length; pathPartIndex++) {
                    state_1 = _loop_1(pathPartIndex);
                    if (typeof state_1 === "object")
                        return [2 /*return*/, state_1.value];
                }
                currentSection = isSectionValue(subList[subIndex])
                    ? subList[subIndex]
                    : undefined;
                currentPart = currentSection
                    ? parentPart
                    : subList[subIndex];
                authError = deltaAllowed(delta.delta, [parentPart === null || parentPart === void 0 ? void 0 : parentPart.editState, currentPart === null || currentPart === void 0 ? void 0 : currentPart.editState].filter(function (x) { return x; }), context.allowedModes);
                if (authError) {
                    return [2 /*return*/, { errorMessage: authError }];
                }
                if (currentPart && delta.delta === "AddPartDelta") {
                    return [2 /*return*/, { errorMessage: "already exists" }];
                }
                if (currentSection && delta.delta === "AddSectionDelta") {
                    return [2 /*return*/, { errorMessage: "already exists" }];
                }
                if (!currentPart &&
                    ![
                        "AddPartDelta",
                        "AddSectionDelta",
                        "GetPartDelta",
                        "GetSectionDelta",
                    ].includes(delta.delta)) {
                    return [2 /*return*/, { errorMessage: "part not found" }];
                }
                if (!currentSection &&
                    delta.delta !== "AddSectionDelta" &&
                    delta.delta.includes("SectionDelta")) {
                    return [2 /*return*/, { errorMessage: "section not found" }];
                }
                // you can't apply part deltas to a section
                if (currentSection && !delta.delta.includes("SectionDelta")) {
                    return [2 /*return*/, {
                            errorMessage: "only *SectionDelta is supported on specific sections",
                        }];
                }
                if (currentPart) {
                    if (currentPart.valueType === "section" &&
                        delta.delta === "EditPartDelta") {
                        return [2 /*return*/, { errorMessage: "direct edit of sections not allowed" }];
                    }
                    if (delta.delta === "AttachFileDelta" &&
                        !supportedFileTypes[currentPart.valueType]) {
                        return [2 /*return*/, { errorMessage: "file attachments not supported" }];
                    }
                    if (delta.delta === "AttachFileDelta" &&
                        !((_b = supportedFileTypes[currentPart.valueType]) === null || _b === void 0 ? void 0 : _b.includes(delta.fileExtension))) {
                        return [2 /*return*/, { errorMessage: "unsupported file type" }];
                    }
                }
                if (!("values" in delta || "valueType" in delta || "typeConditions" in delta)) return [3 /*break*/, 2];
                return [4 /*yield*/, validateValuesAndType(
                    // path prefix
                    pathParts.slice(0, pathParts.length - 2).join("."), currentPart, delta, context)];
            case 1:
                validationErrors = _c.sent();
                if (validationErrors.length > 0) {
                    return [2 /*return*/, { errorMessage: "invalid value", validationErrors: validationErrors }];
                }
                return [3 /*break*/, 4];
            case 2:
                if (!(delta.delta === "LockPartDelta" && delta.editState === "locked")) return [3 /*break*/, 4];
                return [4 /*yield*/, validateValuesAndType(
                    // path prefix
                    pathParts.slice(0, pathParts.length - 2).join("."), currentPart, delta, __assign(__assign({}, context), { validationMode: "fullRecursive" }))];
            case 3:
                validationErrors = _c.sent();
                if (validationErrors.length > 0) {
                    return [2 /*return*/, { errorMessage: "invalid value", validationErrors: validationErrors }];
                }
                _c.label = 4;
            case 4:
                if (!("source" in delta)) return [3 /*break*/, 9];
                if (!(!delta.source.documentId ||
                    delta.source.documentId === context.doc.documentId)) return [3 /*break*/, 5];
                _a = context.doc;
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, context.keval(["get", ".documentId", "."], delta.source)];
            case 6:
                _a = _c.sent();
                _c.label = 7;
            case 7:
                source = _a;
                if (!source) {
                    return [2 /*return*/, { errorMessage: "invalid source doc" }];
                }
                shadowDelta = {
                    delta: delta.delta.includes("Section")
                        ? "GetSectionDelta"
                        : "GetPartDelta",
                    path: delta.source.path,
                };
                return [4 /*yield*/, findDeltaParameters(source.parts, shadowDelta, __assign(__assign({}, context), { doc: source }))];
            case 8:
                shadowParams = _c.sent();
                if ("errorMessage" in shadowParams) {
                    return [2 /*return*/, {
                            errorMessage: "invalid source path: " + shadowParams.errorMessage,
                        }];
                }
                sourcePart = shadowParams.currentPart;
                sourceSection = shadowParams.currentSection;
                _c.label = 9;
            case 9: return [2 /*return*/, {
                    subList: subList,
                    subIndex: subIndex >= 0 ? subIndex : subList.length,
                    currentPart: currentPart,
                    currentSection: currentSection,
                    sourcePart: sourcePart,
                    sourceSection: sourceSection,
                    partKey: pathParts[pathParts.length - 1],
                    ancestorParts: ancestorParts,
                }];
        }
    });
}); };
var stringTypes = ["text", "choice", "checkbox", "markdown"];
var numberTypes = ["number", "integer"];
export var validateValuesAndType = function (pathPrefix, currentPart, delta, // DocumentDelta or empty object
context) { return __awaiter(void 0, void 0, void 0, function () {
    var path, valueType, typeConditions, _a, minOccurances, maxOccurances, uniqueValues, minValue, maxValue, pattern, sourcePaths, skipValidation, customCondition, values, visibilityConditions, vcKexpr, _b, enumValues, source, regExp, errors, _c, _d, sectionValue, _e, _f, part, _g, _h, e_3_1, e_4_1, customConditionValid, err_1;
    var e_4, _j, e_3, _k;
    var _l;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                path = currentPart
                    ? (pathPrefix ? pathPrefix + "." : "") + currentPart.partKey
                    : delta.path;
                valueType = "valueType" in delta ? delta.valueType : currentPart.valueType;
                if ("values" in delta && valueType === "section") {
                    if (currentPart.valueType !== "section") {
                        // override the value passed in if changing the type to section
                        delta.values = [];
                    }
                    else {
                        // the current value can't be modified for sections
                        delta.values = currentPart.values;
                    }
                }
                typeConditions = "typeConditions" in delta
                    ? delta.typeConditions
                    : currentPart === null || currentPart === void 0 ? void 0 : currentPart.typeConditions;
                _a = __assign({ 
                    // default min / max values
                    minOccurances: 0, maxOccurances: 1, uniqueValues: true, skipValidation: false }, typeConditions), minOccurances = _a.minOccurances, maxOccurances = _a.maxOccurances, uniqueValues = _a.uniqueValues, minValue = _a.minValue, maxValue = _a.maxValue, pattern = _a.pattern, sourcePaths = _a.sourcePaths, skipValidation = _a.skipValidation, customCondition = _a.customCondition;
                values = updatedValues(currentPart, delta);
                // don't validate dynamic values since they are not directly writable anyway
                if (skipValidation || isKExpression(values)) {
                    return [2 /*return*/, []];
                }
                visibilityConditions = "visibilityConditions" in delta
                    ? delta.visibilityConditions
                    : currentPart === null || currentPart === void 0 ? void 0 : currentPart.visibilityConditions;
                vcKexpr = isKExpression(visibilityConditions)
                    ? visibilityConditions
                    : { kexpr: visibilityConditions };
                _b = visibilityConditions;
                if (!_b) return [3 /*break*/, 2];
                return [4 /*yield*/, context.keval(vcKexpr, context.doc)];
            case 1:
                _b = !(_m.sent());
                _m.label = 2;
            case 2:
                if (_b) {
                    return [2 /*return*/, []];
                }
                return [4 /*yield*/, context.keval(typeConditions === null || typeConditions === void 0 ? void 0 : typeConditions.enumValues, context.doc)];
            case 3:
                enumValues = (_l = (_m.sent())) === null || _l === void 0 ? void 0 : _l.map(function (ev) { return (Array.isArray(ev) ? ev[0] : ev); });
                console.log("COMPUTED ENUM VALUES", enumValues);
                // min and max occurances must be self-consisten
                if (minOccurances < 0) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                }
                if (maxOccurances !== null &&
                    (maxOccurances < 1 || minOccurances > maxOccurances)) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                }
                // min and max values only make sense for number or string types
                if (!numberTypes.includes(valueType) &&
                    !stringTypes.includes(valueType) &&
                    (minValue != null || maxValue != null)) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                }
                // min and max values are optional but must be self consistent if provided
                if (minValue != null && maxValue != null && minValue > maxValue) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                }
                // enumValues must be given if and only if the type is choice or checkbox
                if (["choice", "checkbox"].includes(valueType) !== (enumValues === null || enumValues === void 0 ? void 0 : enumValues.length) > 0) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                }
                if (pattern && valueType !== "text") {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                }
                if (pattern) {
                    try {
                        // ensure this is a valid regexp
                        new RegExp(pattern);
                    }
                    catch (e) {
                        return [2 /*return*/, [{ path: path, validationError: "Invalid definition" }]];
                    }
                }
                source = "source" in delta ? delta.source : currentPart === null || currentPart === void 0 ? void 0 : currentPart.source;
                if (source &&
                    sourcePaths &&
                    (source.sourceType !== "prototype" ||
                        !sourcePaths.includes(source.path) ||
                        (source.documentId && source.documentId !== context.doc.documentId))) {
                    return [2 /*return*/, [{ path: path, validationError: "Only local sources allowed" }]];
                }
                // derived and raw values don't mix
                if (delta.delta === "EditPartDelta") {
                    if (isKExpression(currentPart.values) !== isKExpression(delta.values)) {
                        return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                    }
                    if (isKExpression(delta.values) &&
                        ["append", "remove"].includes(delta.mode)) {
                        return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                    }
                }
                // validate the values themselves
                // occurance checks
                if (values.length < minOccurances &&
                    context.validationMode !== "allowPartialShallow") {
                    return [2 /*return*/, [{ path: path, validationError: "Required" }]];
                }
                if (maxOccurances !== null && values.length > maxOccurances) {
                    return [2 /*return*/, [{ path: path, validationError: "Too many" }]];
                }
                // string type checks
                if (stringTypes.includes(valueType) &&
                    values.some(function (v) { return typeof v !== "string"; })) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                }
                if ((enumValues === null || enumValues === void 0 ? void 0 : enumValues.length) > 0 &&
                    values.some(function (v) { return typeof v !== "string" || !enumValues.includes(v); })) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                }
                regExp = pattern && new RegExp(pattern);
                if (regExp && values.some(function (v) { return typeof v !== "string" || !regExp.test(v); })) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                }
                // number type checks
                if (numberTypes.includes(valueType) &&
                    !values.every(function (v) { return isNumber(v, valueType); })) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                }
                if (minValue != null &&
                    values.some(function (v) { return asNumber(v, valueType) < minValue; })) {
                    return [2 /*return*/, [{ path: path, validationError: "Must be at least " + minValue }]];
                }
                if (maxValue != null &&
                    values.some(function (v) { return asNumber(v, valueType) > maxValue; })) {
                    return [2 /*return*/, [{ path: path, validationError: "Must be at most " + minValue }]];
                }
                // verify no duplicates if uniqueValues is true
                if (valueType !== "section" &&
                    values.length > 1 &&
                    uniqueValues &&
                    Array.from(new Set(values)).length < values.length) {
                    return [2 /*return*/, [{ path: path, validationError: "Duplicates not allowed" }]];
                }
                if (!(context.validationMode === "fullRecursive" && valueType === "section")) return [3 /*break*/, 17];
                errors = [];
                _m.label = 4;
            case 4:
                _m.trys.push([4, 15, 16, 17]);
                _c = __values(values), _d = _c.next();
                _m.label = 5;
            case 5:
                if (!!_d.done) return [3 /*break*/, 14];
                sectionValue = _d.value;
                _m.label = 6;
            case 6:
                _m.trys.push([6, 11, 12, 13]);
                _e = (e_3 = void 0, __values(sectionValue.parts)), _f = _e.next();
                _m.label = 7;
            case 7:
                if (!!_f.done) return [3 /*break*/, 10];
                part = _f.value;
                _h = (_g = errors).push;
                return [4 /*yield*/, validateValuesAndType("".concat(pathPrefix ? pathPrefix + "." : "").concat(currentPart.partKey, ".").concat(sectionValue.sectionKey), part, {}, context)];
            case 8:
                _h.apply(_g, [_m.sent()]);
                _m.label = 9;
            case 9:
                _f = _e.next();
                return [3 /*break*/, 7];
            case 10: return [3 /*break*/, 13];
            case 11:
                e_3_1 = _m.sent();
                e_3 = { error: e_3_1 };
                return [3 /*break*/, 13];
            case 12:
                try {
                    if (_f && !_f.done && (_k = _e.return)) _k.call(_e);
                }
                finally { if (e_3) throw e_3.error; }
                return [7 /*endfinally*/];
            case 13:
                _d = _c.next();
                return [3 /*break*/, 5];
            case 14: return [3 /*break*/, 17];
            case 15:
                e_4_1 = _m.sent();
                e_4 = { error: e_4_1 };
                return [3 /*break*/, 17];
            case 16:
                try {
                    if (_d && !_d.done && (_j = _c.return)) _j.call(_c);
                }
                finally { if (e_4) throw e_4.error; }
                return [7 /*endfinally*/];
            case 17:
                customConditionValid = !isKExpression(customCondition) || values.length === 0;
                if (!!customConditionValid) return [3 /*break*/, 21];
                _m.label = 18;
            case 18:
                _m.trys.push([18, 20, , 21]);
                return [4 /*yield*/, context.keval(customCondition, __assign(__assign({}, context.doc), { values: values, value: values[0] }))];
            case 19:
                customConditionValid = _m.sent();
                return [3 /*break*/, 21];
            case 20:
                err_1 = _m.sent();
                console.error("Error when computing custom condition", customCondition, err_1);
                return [3 /*break*/, 21];
            case 21:
                if (!customConditionValid) {
                    return [2 /*return*/, [{ path: path, validationError: "Invalid" }]];
                }
                return [2 /*return*/, []];
        }
    });
}); };
var asNumber = function (str, type) {
    if (type === "integer") {
        if (/^(0|[1-9][0-9]*)$/.test(str)) {
            return Number.NaN;
        }
        return parseInt(str, 10);
    }
    if (type === "number") {
        if (/^(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(str)) {
            return Number.NaN;
        }
        return parseFloat(str);
    }
    return Number.NaN;
};
var isNumber = function (str, type) {
    return Number.isNaN(asNumber(str, type));
};
