var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _slop_invocations = {};
// execute func no more frequently than once every delay seconds
// the first invocation will execute immediately
// the last invocation is guaranteed to eventually execute
// invocations in between the first and last invocation may be dropped if invocations occur more frequently than delaySeconds
export var slop = function (key, func, delaySeconds, initialDelaySeconds) {
    if (initialDelaySeconds === void 0) { initialDelaySeconds = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            ((_a = _slop_invocations[key]) !== null && _a !== void 0 ? _a : (_slop_invocations[key] = {})).nextFunc = func;
            // always wait for either this or the in-progress invocation to complete
            if (!_slop_invocations[key].suspend) {
                // execute immediately and then pause for delay seconds
                _slop(key, delaySeconds, initialDelaySeconds);
            }
            return [2 /*return*/];
        });
    });
};
// TODO: add deslop operation to close out any pending slop operations and hold future operations pending a different operation
var _slop = function (key, delaySeconds, initialDelaySeconds) { return __awaiter(void 0, void 0, void 0, function () {
    var func;
    var _a;
    return __generator(this, function (_b) {
        func = (_a = _slop_invocations[key]) === null || _a === void 0 ? void 0 : _a.nextFunc;
        if (!func) {
            // nothing more to do; cancel the suspend so the next invocation can happen immediately
            console.log("Nothing to do");
            _slop_invocations[key].suspend = false;
            return [2 /*return*/];
        }
        // consume nextFunc so it's not executed again
        _slop_invocations[key].nextFunc = null;
        _slop_invocations[key].suspend = true;
        // execute the current invocation after initialDelaySeconds
        setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                func();
                // queue up the next potential invocation
                // we will delay initialDelaySeconds already before the next invocation so subtract that from delaySeconds
                setTimeout(function () {
                    _slop(key, delaySeconds, 0);
                }, Math.round((delaySeconds - initialDelaySeconds) * 1000));
                return [2 /*return*/];
            });
        }); }, Math.round(initialDelaySeconds * 1000));
        return [2 /*return*/];
    });
}); };
