var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
export var sourceProviderFromRequest = function (req) {
    var company = req.params.company;
    var headers = Object.entries(req.headers)
        .filter(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        return ["x-api-key", "authorization"].includes(k.toLowerCase());
    })
        .reduce(function (map, _a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        map[k] = v;
        return map;
    }, {});
    return sourceProviderFromHeaders(company, headers);
};
export var sourceProviderFromHeaders = function (company, headers) {
    var resourceRegex = new RegExp("^(obodo|srs):[a-z]+:/company/".concat(company, "/.+"));
    var api = axios.create();
    // TODO: get retries to work
    // api.defaults.raxConfig = {
    //   instance: api,
    //   retry: 3,
    //   noResponseRetries: 2,
    //   backoffType: "exponential",
    // };
    // const interceptorId = rax.attach(api);
    return {
        company: company,
        get: function (resource) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!resourceRegex.test(resource)) {
                            return [2 /*return*/, resource];
                        }
                        _a = getResource;
                        _b = [api,
                            resource];
                        if (!(typeof headers === "function")) return [3 /*break*/, 2];
                        return [4 /*yield*/, headers()];
                    case 1:
                        _c = _d.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _c = headers;
                        _d.label = 3;
                    case 3: return [4 /*yield*/, _a.apply(void 0, _b.concat([_c]))];
                    case 4: return [2 /*return*/, _d.sent()];
                }
            });
        }); },
        list: function (resource, listAll) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!resourceRegex.test(resource)) {
                            return [2 /*return*/, resource];
                        }
                        _a = listResources;
                        _b = [api,
                            resource,
                            listAll];
                        if (!(typeof headers === "function")) return [3 /*break*/, 2];
                        return [4 /*yield*/, headers()];
                    case 1:
                        _c = _d.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _c = headers;
                        _d.label = 3;
                    case 3: return [4 /*yield*/, _a.apply(void 0, _b.concat([_c]))];
                    case 4: return [2 /*return*/, (_d.sent()).results];
                }
            });
        }); },
    };
};
// TODO: this can't account for the number of calls used by each listAll
export var useLimit = function (callLimit, sourceProvider) {
    return {
        company: sourceProvider.company,
        get: function (resource) {
            callLimit -= 1;
            if (callLimit < 0) {
                throw new Error("Call limit exceeeded");
            }
            console.log("GETTING", resource);
            return sourceProvider.get(resource);
        },
        list: function (resource, listAll) {
            callLimit -= 1;
            if (callLimit < 0) {
                throw new Error("Call limit exceeeded");
            }
            console.log("LISTING", resource, listAll);
            return sourceProvider.list(resource, listAll);
        },
    };
};
// export const useLimit = (
//   callLimit: number,
//   sourceProvider: SourceProvider
// ): SourceProvider => {
//   return {
//     get: (resource: string) => {
//       if (callLimit <= 0) {
//         throw new Error("Call limit exceeded");
//       }
//       callLimit -= 1;
//       console.log("GETTING", resource);
//       return sourceProvider.get(resource);
//     },
//     list: async (resource: string, listAll: boolean, pageToken: string | undefined) => {
//       if (callLimit <= 0) {
//         throw new Error("Call limit exceeded");
//       }
//       const results = [];
//       do {
//         callLimit -= 1;
//         console.log("LISTING", resource, listAll);
//         const result = await sourceProvider.list(resource, false, pageToken);
//         results.push(...result.results);
//         pageToken = result.pageToken;
//       } while (listAll && !pageToken && callLimit > 0);
//       if (listAll && callLimit <= 0 && pageToken) {
//         throw new Error("Call limit exceeded");
//
//       }
//       return { results, pageToken };
//     },
//   };
// };
export var useCaching = function (sourceProvider) {
    var cache = {};
    return {
        company: sourceProvider.company,
        get: function (resource) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            var _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!((_c = cache[_d = "get__" + resource]) !== null && _c !== void 0)) return [3 /*break*/, 1];
                        _a = _c;
                        return [3 /*break*/, 3];
                    case 1:
                        _b = cache;
                        return [4 /*yield*/, sourceProvider.get(resource)];
                    case 2:
                        _a = (_b[_d] = _e.sent());
                        _e.label = 3;
                    case 3: return [2 /*return*/, (_a)];
                }
            });
        }); },
        list: function (resource, listAll) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            var _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!((_c = cache[_d = (listAll ? "listAll__" : "list__") + resource]) !== null && _c !== void 0)) return [3 /*break*/, 1];
                        _a = _c;
                        return [3 /*break*/, 3];
                    case 1:
                        _b = cache;
                        return [4 /*yield*/, sourceProvider.list(resource, listAll)];
                    case 2:
                        _a = (_b[_d] = _e.sent());
                        _e.label = 3;
                    case 3: return [2 /*return*/, (_a)];
                }
            });
        }); },
    };
};
export var useOptimizedCaching = function (sourceProvider) {
    var cache = {};
    // leverage list results to extract singular results
    var cacheResourceFromList = function (getKey, listEntry, resource) {
        var _a;
        var idField = resourceIdField(resource);
        var id = resource.split("/").pop();
        var value = listEntry.result.find(function (r) { return r[idField] === id; });
        if (listEntry.type === "listAll" || value) {
            console.log("EXTRACTING RESULT", resource, listEntry.type, value === null || value === void 0 ? void 0 : value.displayName);
            // don't override existing results if they exist
            return Promise.resolve(((_a = cache[getKey]) === null || _a === void 0 ? void 0 : _a.result)
                ? cache[getKey]
                : (cache[getKey] = {
                    type: "get",
                    resource: resource,
                    result: value,
                }));
        }
        else {
            console.log("CACHE MISS", resource, listEntry.type);
            listEntry.missCount += 1;
            // throw here to prevent this result from being used in a Promise.any() call
            return Promise.reject(new Error("cache miss"));
        }
    };
    var listMissThreshold = 3;
    var listAllMissThreshold = 6;
    // NOTE: Purposefully not marked as async here because I don't want any await calls
    // the execution of this function should be fully concurrent (to prevent race conditions when inspecting the cache)
    // only the processing of the resulting promise (and recording final results into the cache) should be async
    var probeCache = function (type, resource) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var listResource = type !== "get" ? resource : resource.split("/").slice(0, -1).join("/");
        var listAllKey = "la__" + listResource;
        var listKey = type !== "listAll" && "l__" + listResource;
        var getKey = type === "get" && "g__" + resource;
        var promises = [];
        // get cache hit - return it immediately
        if ((_a = cache[getKey]) === null || _a === void 0 ? void 0 : _a.result) {
            console.log("CACHE HIT", type, resource);
            return cache[getKey];
        }
        // list cache hit
        var listEntry = (_b = cache[listAllKey]) !== null && _b !== void 0 ? _b : cache[listKey];
        if ((listEntry === null || listEntry === void 0 ? void 0 : listEntry.result) && type !== "get") {
            console.log("CACHE HIT", type, resource);
            return listEntry;
        }
        else if (listEntry === null || listEntry === void 0 ? void 0 : listEntry.result) {
            // potential hit
            console.log("POTENTIAL HIT", type, resource);
            promises.push(cacheResourceFromList(getKey, listEntry, resource));
        }
        else {
            // no list cache hit - check for relevant pending promises
            promises.push.apply(promises, __spreadArray([], __read([listAllKey, listKey]
                .map(function (key) { var _a; return (_a = cache[key]) === null || _a === void 0 ? void 0 : _a.promise; })
                .filter(function (p) { return p; })), false));
        }
        var hasListPromise = promises.length > 0;
        if (hasListPromise) {
            console.log("PENDING", type, resource);
        }
        // no cache hit or pending promises - create new list promise if applicable
        // list all
        if (type === "listAll" ||
            (!hasListPromise &&
                type === "get" &&
                ((_c = cache[listAllKey]) === null || _c === void 0 ? void 0 : _c.missCount) > listAllMissThreshold)) {
            var listAllEntryPromise = (cache[listAllKey] = {
                type: "listAll",
                resource: listResource,
                missCount: (_e = (_d = cache[listAllKey]) === null || _d === void 0 ? void 0 : _d.missCount) !== null && _e !== void 0 ? _e : 0,
                promise: sourceProvider.list(listResource, true).then(function (result) {
                    return (cache[listAllKey] = {
                        type: "listAll",
                        resource: listResource,
                        result: result,
                    });
                }),
            }).promise;
            if (type === "listAll") {
                return listAllEntryPromise;
            }
            else {
                promises.push(listAllEntryPromise.then(function (listAllEntry) {
                    return cacheResourceFromList(getKey, listAllEntry, resource);
                }));
            }
        }
        else if (type === "list" ||
            (!hasListPromise &&
                type === "get" &&
                ((_f = cache[listKey]) === null || _f === void 0 ? void 0 : _f.missCount) > listMissThreshold)) {
            var listEntryPromise = (cache[listKey] = {
                type: "list",
                resource: listResource,
                missCount: (_h = (_g = cache[listKey]) === null || _g === void 0 ? void 0 : _g.missCount) !== null && _h !== void 0 ? _h : 0,
                promise: sourceProvider.list(listResource, false).then(function (result) {
                    return (cache[listKey] = {
                        type: "list",
                        resource: listResource,
                        result: result,
                    });
                }),
            }).promise;
            if (type === "list") {
                return listEntryPromise;
            }
            else {
                promises.push(listEntryPromise.then(function (listEntry) {
                    return cacheResourceFromList(getKey, listEntry, resource);
                }));
            }
        }
        else if (type === "get" && !hasListPromise) {
            // increase miss counts
            (_j = cache[listKey]) !== null && _j !== void 0 ? _j : (cache[listKey] = {
                type: "list",
                resource: listResource,
                missCount: 0,
            });
            cache[listKey].missCount += 1;
            (_k = cache[listAllKey]) !== null && _k !== void 0 ? _k : (cache[listAllKey] = {
                type: "listAll",
                resource: listResource,
                missCount: 0,
            });
            cache[listAllKey].missCount += 1;
        }
        // get call as a backup to any list promises
        return Promise.any(promises).catch(function () {
            var _a;
            return ((_a = cache[getKey]) !== null && _a !== void 0 ? _a : (cache[getKey] = {
                type: "get",
                resource: resource,
                promise: sourceProvider.get(resource).then(function (result) {
                    return (cache[getKey] = {
                        type: "get",
                        resource: resource,
                        result: result,
                    });
                }),
            })).promise;
        });
    };
    return {
        company: sourceProvider.company,
        get: function (resource) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, probeCache("get", resource)];
                    case 1: return [2 /*return*/, (_a.sent()).result];
                }
            });
        }); },
        list: function (resource, listAll) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, probeCache(listAll ? "listAll" : "list", resource)];
                    case 1: return [2 /*return*/, (_a.sent()).result];
                }
            });
        }); },
    };
};
export var getResource = function (api, resource, headers) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchResource(api, resourceUrl(resource), headers)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var listResources = function (api, resource, listAll, headers, pageToken) {
    if (pageToken === void 0) { pageToken = undefined; }
    return __awaiter(void 0, void 0, void 0, function () {
        var results, url, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    results = [];
                    url = resourceUrl(resource);
                    _a.label = 1;
                case 1: return [4 /*yield*/, fetchResource(api, url + (pageToken ? "?pageToken=" + pageToken : ""), headers)];
                case 2:
                    result = _a.sent();
                    pageToken = result.pageToken;
                    // all list APIs return two fields; one with the list results and an optional field called pageToken
                    results.push.apply(results, __spreadArray([], __read(Object.entries(result).find(function (_a) {
                        var _b = __read(_a, 2), k = _b[0], v = _b[1];
                        return k !== "pageToken";
                    })[1]), false));
                    _a.label = 3;
                case 3:
                    if (listAll && pageToken) return [3 /*break*/, 1];
                    _a.label = 4;
                case 4: return [2 /*return*/, {
                        results: results,
                        pageToken: pageToken,
                    }];
            }
        });
    });
};
var fetchResource = function (api, url, headers) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, data, e_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log("External API call - GET", url);
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, api.get(url, {
                        mode: "cors",
                        withCredentials: false,
                        headers: headers,
                    })];
            case 2:
                resp = _b.sent();
                data = resp.data.data;
                console.log("Succeeded - External API call - GET", url);
                return [2 /*return*/, data];
            case 3:
                e_1 = _b.sent();
                if (((_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                    console.error("Not Found - External API call - GET", url);
                    console.error(e_1);
                    return [2 /*return*/, null];
                }
                console.error("Failed - External API call - GET", url);
                console.error(e_1);
                throw e_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
// converts resource strings to the id field for that resource. For example:
// * "obodo:data:/company/srs/documents" => "documentId"
// * "obodo:data:/company/srs/documents/abcdef012345" => "documentId"
// * "srs:placements:/company/srs/partners/abcdef012345/journey-groups" => "journeyGroupId"
// * "srs:ledger:/company/srs/pors/abcdef012345" => "planOfRecordId"
var resourceIdField = function (resource) {
    var parts = resource.split("/");
    var type = parts.length % 2 === 0 ? parts[parts.length - 1] : parts[parts.length - 2];
    // replace abbreviations, remove plurality, convert from kebab or snake case to camel case and end it with "Id"
    return (type
        .replace(/^pors$/, "plan-of-records")
        .replace(/s$/, "")
        .replace(/[-_]([a-z])/g, function (_, l) { return l.toUpperCase(); }) + "Id");
};
var resourceUrl = function (resource) {
    var _a = __read(resource.split("/")), resourcePrefix = _a[0], parts = _a.slice(1);
    var _b = __read(resourcePrefix.split(":", 2), 2), provider = _b[0], service = _b[1];
    return "https://".concat(stage() !== "prod" ? "test-" : "").concat(service, ".").concat({
        obodo: "obodo.app",
        srs: "studentroomstay.com",
    }[provider], "/api/").concat(parts.join("/"));
};
var _stage;
var stage = function () {
    var _a, _b;
    if (!_stage) {
        if (typeof window !== "undefined") {
            _stage =
                window.location.origin === "https://amp.studentroomstay.com"
                    ? "prod"
                    : "test";
        }
        else if (process) {
            _stage = (_b = (_a = process.env.kevalFetchStage) !== null && _a !== void 0 ? _a : process.env.stage) !== null && _b !== void 0 ? _b : "test";
        }
        else {
            _stage = "test";
        }
    }
    return _stage;
};
