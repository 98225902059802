var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var booleanType = { type: "boolean" };
var numberType = { type: "number" };
var stringType = { type: "string" };
var nullType = { type: "null" };
var valueType = {
    type: "union",
    types: [booleanType, numberType, stringType],
};
var comparableType = {
    type: "union",
    types: [numberType, stringType],
};
var nullableValueType = {
    type: "union",
    types: [booleanType, numberType, stringType, nullType],
};
var stringArrayType = { type: "array", items: stringType };
var valueArrayType = { type: "array", items: valueType };
var anyType = { type: "any" };
var anyArrayType = { type: "array", items: anyType };
var currencyString = function (currency) {
    if (currency == null ||
        currency.currencyCode == null ||
        currency.amount == null) {
        return currency;
    }
    var digits = 2; // TODO: this should be determined by the currencyCode
    var format = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency.currencyCode,
        minimumFractionDigits: digits,
    });
    return format.format(currency.amount / Math.pow(10, digits));
};
var rateString = function (rate) {
    if (rate == null ||
        rate.periodPrice == null ||
        rate.periodType == null ||
        rate.periodLength == null) {
        return rate;
    }
    return "".concat(currencyString(rate.periodPrice)).concat(rate.periodType === "unit"
        ? ""
        : " per ".concat(rate.periodLength, " ").concat(rate.periodType, "s"));
};
var functions = {
    is: {
        params: [nullableValueType, nullableValueType],
        eval: function (a, b) { return (a == null && b == null) || a === b; },
    },
    oneOf: {
        params: [valueArrayType, __assign(__assign({}, anyType), { variadic: true })],
        eval: function (a) {
            var b = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                b[_i - 1] = arguments[_i];
            }
            return b.flat().some(function (bx) { return a.includes(bx); });
        },
    },
    all: {
        params: [__assign(__assign({}, nullableValueType), { variadic: true })],
        eval: function () {
            var a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                a[_i] = arguments[_i];
            }
            return a.every(function (ax) { return ax; });
        },
    },
    some: {
        params: [__assign(__assign({}, nullableValueType), { variadic: true })],
        eval: function () {
            var a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                a[_i] = arguments[_i];
            }
            return a.some(function (ax) { return ax; });
        },
    },
    none: {
        params: [__assign(__assign({}, nullableValueType), { variadic: true })],
        eval: function () {
            var a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                a[_i] = arguments[_i];
            }
            return a.every(function (ax) { return !ax; });
        },
    },
    first: {
        params: [__assign(__assign({}, anyType), { variadic: true })],
        eval: function () {
            var a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                a[_i] = arguments[_i];
            }
            return a.flat().find(function (x) { return x; });
        },
    },
    join: {
        params: [
            stringType,
            {
                type: "union",
                types: [stringArrayType, stringType],
                variadic: true,
            },
        ],
        eval: function (a) {
            var b = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                b[_i - 1] = arguments[_i];
            }
            return b.flat().join(a);
        },
    },
    flatten: {
        params: [__assign(__assign({}, anyType), { variadic: true })],
        eval: function () {
            var a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                a[_i] = arguments[_i];
            }
            return a.flat(2);
        },
    },
    if: {
        params: [anyType, anyType, __assign(__assign({}, anyType), { optional: true })],
        eval: function (c, t, f) {
            if (f === void 0) { f = undefined; }
            return (c ? t : f);
        },
    },
    isoDate: {
        params: [stringType],
        eval: function (a) { return a && new Date(a).toISOString().slice(0, 10); },
    },
    isoDateTime: {
        params: [stringType],
        eval: function (a) { return a && new Date(a).toISOString(); },
    },
    currencyString: {
        params: [anyType],
        eval: currencyString,
    },
    rateString: {
        params: [anyType],
        eval: rateString,
    },
    "<=": {
        params: [comparableType, comparableType],
        eval: function (a, b) { return a <= b; },
    },
    ">=": {
        params: [comparableType, comparableType],
        eval: function (a, b) { return a >= b; },
    },
    "<": {
        params: [comparableType, comparableType],
        eval: function (a, b) { return a < b; },
    },
    ">": {
        params: [comparableType, comparableType],
        eval: function (a, b) { return a > b; },
    },
};
var isValid = function (func, params) {
    var isVariadic = func.params[func.params.length - 1].variadic;
    // const minParams = func.params.findLastIndex((p, i) => !p.optional && (i < (func.params.length - 1) || !p.variadic)) + 1;
    var minParams = func.params.filter(function (p, i) { return !p.optional && (i < func.params.length - 1 || !p.variadic); }).length;
    if (params.length < minParams) {
        return false;
    }
    if (params.length > func.params.length && !isVariadic) {
        return false;
    }
    for (var i = 0; i < params.length; i++) {
        if (!isType(func.params[i >= func.params.length ? func.params.length - 1 : i], params[i])) {
            return false;
        }
    }
    return true;
};
var isType = function (type, value) {
    if (type.type === "any") {
        return true;
    }
    else if (type.type === "boolean") {
        return typeof value === "boolean";
    }
    else if (type.type === "string") {
        return typeof value === "string";
    }
    else if (type.type === "number") {
        return typeof value === "number";
    }
    else if (type.type === "null") {
        return value == null;
    }
    else if (type.type === "array") {
        return Array.isArray(value) && value.every(function (x) { return isType(type.items, x); });
    }
    else if (type.type === "object") {
        // TODO: what to do here?
        return true;
    }
    else if (type.type === "union") {
        return type.types.some(function (t) { return isType(t, value); });
    }
    else {
        throw new Error("Unknown type: " + JSON.stringify(type));
    }
};
var extract = function (source, path, isStrict) {
    var e_1, _a;
    if (isStrict === void 0) { isStrict = false; }
    if (source == null) {
        if (isStrict) {
            throw new Error("Missing source for path: " + JSON.stringify(path));
        }
        else {
            return undefined;
        }
    }
    // console.log("EXTRACTING", path, source);
    path = path.slice(1);
    // handle the special structure of documents
    if (source.type === "Document" &&
        ![
            "typeState",
            "createdAt",
            "lastModifiedAt",
            "displayName",
            "visibility",
            "company",
            "value",
            "values",
        ].includes(path)) {
        var part = getPartOrSection(source, path);
        if (!part && isStrict) {
            throw new Error("Invalid path: " + JSON.stringify({ source: source, path: path }));
        }
        return fragmentJson(part);
    }
    // otherwise treat this as a standard json object
    var obj = source;
    var pathParts = path.split(".");
    try {
        for (var _b = __values(path.split(".")), _c = _b.next(); !_c.done; _c = _b.next()) {
            var pathPart = _c.value;
            var keys = Object.keys(obj !== null && obj !== void 0 ? obj : {});
            // this somehow even works for array indicies
            // e.g. [1,2,3]["1"] => 2
            // Javascript is weird but that is useful here
            // this allows a path of "hello.0" on { "hello": [1, 2, 3] } to return 1
            obj = obj[pathPart];
            if (obj == null) {
                if (isStrict) {
                    throw new Error("Invalid path: " + JSON.stringify({ source: source, path: path }));
                }
                else {
                    return obj;
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return obj;
};
var resourceRegex = /^(obodo|srs):[a-z]+:\/company\//;
var extractResource = function (currentSource, query, company) {
    // this is a raw string
    if (!query.startsWith(".")) {
        return query;
    }
    var id = extract(currentSource, query);
    // console.log("Extracting resource", query, id);
    if (typeof id !== "string" || resourceRegex.test(id)) {
        // this id is already a resource identifier or other value
        return id;
    }
    // infer the structure of the resource identifier from the field name
    var params;
    var field = query.toLowerCase();
    if (field.endsWith("documentid")) {
        params = ["obodo:data:", "documents"];
    }
    else if (field.endsWith("actionid")) {
        params = ["obodo:data:", "actions"];
    }
    else if (field.endsWith("userid")) {
        params = ["obodo:users:", "users"];
    }
    else if (field.endsWith("roleid")) {
        params = ["obodo:users:", "roles"];
    }
    else if (field.endsWith("accountid")) {
        params = ["srs:ledger:", "accounts"];
    }
    else if (field.endsWith("planofrecordid") || field.endsWith("porid")) {
        params = ["srs:ledger:", "pors"];
    }
    else if (field.endsWith("invoiceid")) {
        params = ["srs:ledger:", "invoices"];
    }
    else if (field.endsWith("payoutid")) {
        params = ["srs:ledger:", "payouts"];
    }
    else if (field.endsWith("payoutbatchid")) {
        params = ["srs:ledger:", "payout-batches"];
    }
    else if (field.endsWith("productid")) {
        params = ["srs:ledger:", "products"];
    }
    else if (field.endsWith("productfamilyid")) {
        params = ["srs:ledger:", "product-families"];
    }
    else if (field.endsWith("providerid")) {
        params = ["srs:placements:", "providers"];
    }
    else if (field.endsWith("guestid")) {
        params = ["srs:placements:", "guests"];
    }
    else if (field.endsWith("partnerid")) {
        params = ["srs:placements:", "partners"];
    }
    else if (field.endsWith("journeygroupid")) {
        params = ["srs:placements:", "journey-groups"];
    }
    else if (field.endsWith("journeyid")) {
        params = ["srs:placements:", "journeys"];
    }
    else if (field.endsWith("placementid")) {
        params = ["srs:placements:", "placements"];
    }
    else {
        // unknown format - return raw id
        return id;
    }
    var _a = __read(params, 2), prefix = _a[0], path = _a[1];
    // TODO: is there any case where we actually want to allow crossing a company boundary?
    // const company = extract(currentSource, ".company");
    var resource = [prefix, "company", company, path, id].join("/");
    // console.log("Resource", resource);
    return resource;
};
export var fragmentJson = function (fragment) {
    var e_2, _a;
    if (!fragment) {
        return undefined;
    }
    // maxOccurrances defaults to 1 - may be explicitly set to null to represent no limit
    var singular = __assign({ maxOccurances: 1 }, fragment.typeConditions).maxOccurances === 1;
    if (fragment.valueType === "section") {
        var values = fragment.values.map(function (v) { return fragmentJson(v); });
        return singular ? values[0] : values;
    }
    else if (fragment.type === "Document" || fragment.sectionKey) {
        var json = {};
        try {
            for (var _b = __values(fragment.parts), _c = _b.next(); !_c.done; _c = _b.next()) {
                var part = _c.value;
                json[part.partKey] = fragmentJson(part);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return json;
    }
    else if (singular) {
        return fragment.values[0];
    }
    else {
        return fragment.values;
    }
};
export var expandKeval = function (fragment, keval) { return __awaiter(void 0, void 0, void 0, function () {
    var values, _a, _b, v, _c, _d, e_3_1, parts, _e, _f, p, _g, _h, e_4_1, enumValues, values;
    var e_3, _j, e_4, _k;
    var _l;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                if (!!fragment) return [3 /*break*/, 1];
                return [2 /*return*/, undefined];
            case 1:
                if (!(fragment.valueType === "section")) return [3 /*break*/, 10];
                values = [];
                _m.label = 2;
            case 2:
                _m.trys.push([2, 7, 8, 9]);
                _a = __values(fragment.values), _b = _a.next();
                _m.label = 3;
            case 3:
                if (!!_b.done) return [3 /*break*/, 6];
                v = _b.value;
                _d = (_c = values).push;
                return [4 /*yield*/, expandKeval(v, keval)];
            case 4:
                _d.apply(_c, [_m.sent()]);
                _m.label = 5;
            case 5:
                _b = _a.next();
                return [3 /*break*/, 3];
            case 6: return [3 /*break*/, 9];
            case 7:
                e_3_1 = _m.sent();
                e_3 = { error: e_3_1 };
                return [3 /*break*/, 9];
            case 8:
                try {
                    if (_b && !_b.done && (_j = _a.return)) _j.call(_a);
                }
                finally { if (e_3) throw e_3.error; }
                return [7 /*endfinally*/];
            case 9: return [2 /*return*/, __assign(__assign({}, fragment), { values: values })];
            case 10:
                if (!(fragment.type === "Document" || fragment.sectionKey)) return [3 /*break*/, 19];
                parts = [];
                _m.label = 11;
            case 11:
                _m.trys.push([11, 16, 17, 18]);
                _e = __values(fragment.parts), _f = _e.next();
                _m.label = 12;
            case 12:
                if (!!_f.done) return [3 /*break*/, 15];
                p = _f.value;
                _h = (_g = parts).push;
                return [4 /*yield*/, expandKeval(p, keval)];
            case 13:
                _h.apply(_g, [_m.sent()]);
                _m.label = 14;
            case 14:
                _f = _e.next();
                return [3 /*break*/, 12];
            case 15: return [3 /*break*/, 18];
            case 16:
                e_4_1 = _m.sent();
                e_4 = { error: e_4_1 };
                return [3 /*break*/, 18];
            case 17:
                try {
                    if (_f && !_f.done && (_k = _e.return)) _k.call(_e);
                }
                finally { if (e_4) throw e_4.error; }
                return [7 /*endfinally*/];
            case 18: return [2 /*return*/, __assign(__assign({}, fragment), { parts: parts })];
            case 19:
                if (!isKExpression((_l = fragment.typeConditions) === null || _l === void 0 ? void 0 : _l.enumValues)) return [3 /*break*/, 21];
                return [4 /*yield*/, keval(fragment.typeConditions.enumValues)];
            case 20:
                enumValues = _m.sent();
                fragment = __assign(__assign({}, fragment), { typeConditions: __assign(__assign({}, fragment.typeConditions), { enumValues: enumValues != null ? enumValues : [] }) });
                _m.label = 21;
            case 21:
                if (!isKExpression(fragment.values)) return [3 /*break*/, 23];
                return [4 /*yield*/, keval(fragment.values)];
            case 22:
                values = _m.sent();
                console.log({ values: values, func: fragment.values });
                fragment = __assign(__assign({}, fragment), { values: Array.isArray(values) ? values : values != null ? [values] : [] });
                _m.label = 23;
            case 23: return [2 /*return*/, fragment];
        }
    });
}); };
export var isKExpression = function (x) { return (x === null || x === void 0 ? void 0 : x.kexpr) != null; };
var Keval = /** @class */ (function (_super) {
    __extends(Keval, _super);
    function Keval(currentSource, sourceProvider, isStrict) {
        if (isStrict === void 0) { isStrict = true; }
        var _this = _super.call(this) || this;
        return (function (expression, source) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isKExpression(expression)) {
                            return [2 /*return*/, expression];
                        }
                        return [4 /*yield*/, keval(source !== null && source !== void 0 ? source : currentSource, sourceProvider, expression.kexpr, isStrict)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); });
    }
    return Keval;
}(Function));
export { Keval };
export var keval = function (currentSource, sourceProvider, expression, isStrict) {
    if (isStrict === void 0) { isStrict = true; }
    return __awaiter(void 0, void 0, void 0, function () {
        var result, fetched, i, value, _a, _b, value, _c, listed, _d, promises, _e, _f, fetched, _g, _h, e_5_1, value, _j, listed, _k, promises, _loop_1, _l, _m, item, e_6_1, value, _o, listed, _p, promises, _loop_2, _q, _r, item, e_7_1, func, params, _s, _t, param, _u, _v, e_8_1;
        var e_5, _w, e_6, _x, e_7, _y, e_8, _z;
        var _0;
        return __generator(this, function (_1) {
            switch (_1.label) {
                case 0:
                    if (!Array.isArray(expression)) return [3 /*break*/, 74];
                    if (!(expression[0] === "raw")) return [3 /*break*/, 1];
                    result = expression[1];
                    return [3 /*break*/, 73];
                case 1:
                    if (!(expression[0] === "get" && expression.length > 2)) return [3 /*break*/, 11];
                    fetched = currentSource;
                    i = 1;
                    _1.label = 2;
                case 2:
                    if (!(i < expression.length)) return [3 /*break*/, 10];
                    if (!(typeof expression[i] === "string" && i < expression.length - 1)) return [3 /*break*/, 3];
                    _a = extractResource(fetched, expression[i], sourceProvider.company);
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, keval(fetched, sourceProvider, expression[i], isStrict)];
                case 4:
                    _a = _1.sent();
                    _1.label = 5;
                case 5:
                    value = _a;
                    if (!(typeof value === "string" &&
                        resourceRegex.test(value) &&
                        i < expression.length - 1)) return [3 /*break*/, 7];
                    return [4 /*yield*/, sourceProvider.get(value)];
                case 6:
                    _b = _1.sent();
                    return [3 /*break*/, 8];
                case 7:
                    _b = value;
                    _1.label = 8;
                case 8:
                    fetched = _b;
                    _1.label = 9;
                case 9:
                    i += 1;
                    return [3 /*break*/, 2];
                case 10:
                    result = fetched;
                    return [3 /*break*/, 73];
                case 11:
                    if (!(expression[0] === "list" || expression[0] === "listAll")) return [3 /*break*/, 29];
                    if (!(typeof expression[1] === "string")) return [3 /*break*/, 12];
                    _c = extractResource(currentSource, expression[1], sourceProvider.company);
                    return [3 /*break*/, 14];
                case 12: return [4 /*yield*/, keval(currentSource, sourceProvider, expression[1], isStrict)];
                case 13:
                    _c = _1.sent();
                    _1.label = 14;
                case 14:
                    value = _c;
                    if (!(typeof value === "string" && resourceRegex.test(value))) return [3 /*break*/, 16];
                    return [4 /*yield*/, sourceProvider.list(value, expression[0] === "listAll")];
                case 15:
                    _d = _1.sent();
                    return [3 /*break*/, 17];
                case 16:
                    _d = value;
                    _1.label = 17;
                case 17:
                    listed = _d;
                    if (!expression[2]) return [3 /*break*/, 27];
                    promises = [];
                    _1.label = 18;
                case 18:
                    _1.trys.push([18, 23, 24, 25]);
                    _e = __values(Array.isArray(listed) ? listed : [listed]), _f = _e.next();
                    _1.label = 19;
                case 19:
                    if (!!_f.done) return [3 /*break*/, 22];
                    fetched = _f.value;
                    _h = (_g = promises).push;
                    // TODO: omitting await here and using Promise.all was breaking the optimized caching
                    // Fix the optimized caching algorithm and then re-enable by deleting "await"
                    return [4 /*yield*/, keval(fetched, sourceProvider, expression[2], isStrict)];
                case 20:
                    _h.apply(_g, [
                        // TODO: omitting await here and using Promise.all was breaking the optimized caching
                        // Fix the optimized caching algorithm and then re-enable by deleting "await"
                        _1.sent()]);
                    _1.label = 21;
                case 21:
                    _f = _e.next();
                    return [3 /*break*/, 19];
                case 22: return [3 /*break*/, 25];
                case 23:
                    e_5_1 = _1.sent();
                    e_5 = { error: e_5_1 };
                    return [3 /*break*/, 25];
                case 24:
                    try {
                        if (_f && !_f.done && (_w = _e.return)) _w.call(_e);
                    }
                    finally { if (e_5) throw e_5.error; }
                    return [7 /*endfinally*/];
                case 25: return [4 /*yield*/, Promise.all(promises)];
                case 26:
                    result = _1.sent();
                    return [3 /*break*/, 28];
                case 27:
                    result = listed;
                    _1.label = 28;
                case 28: return [3 /*break*/, 73];
                case 29:
                    if (!(expression[0] === "filter" || expression[0] === "filterAll")) return [3 /*break*/, 45];
                    if (!(typeof expression[1] === "string")) return [3 /*break*/, 30];
                    _j = extractResource(currentSource, expression[1], sourceProvider.company);
                    return [3 /*break*/, 32];
                case 30: return [4 /*yield*/, keval(currentSource, sourceProvider, expression[1], isStrict)];
                case 31:
                    _j = _1.sent();
                    _1.label = 32;
                case 32:
                    value = _j;
                    if (!(typeof value === "string" && resourceRegex.test(value))) return [3 /*break*/, 34];
                    return [4 /*yield*/, sourceProvider.list(value, expression[0] === "filterAll")];
                case 33:
                    _k = _1.sent();
                    return [3 /*break*/, 35];
                case 34:
                    _k = value;
                    _1.label = 35;
                case 35:
                    listed = _k;
                    promises = [];
                    _loop_1 = function (item) {
                        var _2, _3;
                        return __generator(this, function (_4) {
                            switch (_4.label) {
                                case 0:
                                    // TODO: omitting await here and using Promise.all was breaking the optimized caching
                                    // Fix the optimized caching algorithm and then re-enable by deleting "await"
                                    _3 = (_2 = promises).push;
                                    return [4 /*yield*/, keval(item, sourceProvider, expression[2], isStrict).then(function (include) { return [item, include]; })];
                                case 1:
                                    // TODO: omitting await here and using Promise.all was breaking the optimized caching
                                    // Fix the optimized caching algorithm and then re-enable by deleting "await"
                                    _3.apply(_2, [_4.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _1.label = 36;
                case 36:
                    _1.trys.push([36, 41, 42, 43]);
                    _l = __values(Array.isArray(listed) ? listed : [listed]), _m = _l.next();
                    _1.label = 37;
                case 37:
                    if (!!_m.done) return [3 /*break*/, 40];
                    item = _m.value;
                    return [5 /*yield**/, _loop_1(item)];
                case 38:
                    _1.sent();
                    _1.label = 39;
                case 39:
                    _m = _l.next();
                    return [3 /*break*/, 37];
                case 40: return [3 /*break*/, 43];
                case 41:
                    e_6_1 = _1.sent();
                    e_6 = { error: e_6_1 };
                    return [3 /*break*/, 43];
                case 42:
                    try {
                        if (_m && !_m.done && (_x = _l.return)) _x.call(_l);
                    }
                    finally { if (e_6) throw e_6.error; }
                    return [7 /*endfinally*/];
                case 43: return [4 /*yield*/, Promise.all(promises)];
                case 44:
                    result = (_1.sent())
                        .filter(function (_a) {
                        var _b = __read(_a, 2), item = _b[0], include = _b[1];
                        return include;
                    })
                        .map(function (_a) {
                        var _b = __read(_a, 2), item = _b[0], include = _b[1];
                        return item;
                    });
                    return [3 /*break*/, 73];
                case 45:
                    if (!(expression[0] === "sort" || expression[0] === "sortAll")) return [3 /*break*/, 63];
                    if (!(typeof expression[1] === "string")) return [3 /*break*/, 46];
                    _o = extractResource(currentSource, expression[1], sourceProvider.company);
                    return [3 /*break*/, 48];
                case 46: return [4 /*yield*/, keval(currentSource, sourceProvider, expression[1], isStrict)];
                case 47:
                    _o = _1.sent();
                    _1.label = 48;
                case 48:
                    value = _o;
                    if (!(typeof value === "string" && resourceRegex.test(value))) return [3 /*break*/, 50];
                    return [4 /*yield*/, sourceProvider.list(value, expression[0] === "filterAll")];
                case 49:
                    _p = _1.sent();
                    return [3 /*break*/, 51];
                case 50:
                    _p = value;
                    _1.label = 51;
                case 51:
                    listed = _p;
                    promises = [];
                    if (!expression[2]) return [3 /*break*/, 60];
                    _loop_2 = function (item) {
                        var _5, _6;
                        return __generator(this, function (_7) {
                            switch (_7.label) {
                                case 0:
                                    // TODO: omitting await here and using Promise.all was breaking the optimized caching
                                    // Fix the optimized caching algorithm and then re-enable by deleting "await"
                                    _6 = (_5 = promises).push;
                                    return [4 /*yield*/, keval(item, sourceProvider, expression[2], isStrict).then(function (sortValue) { return [item, sortValue]; })];
                                case 1:
                                    // TODO: omitting await here and using Promise.all was breaking the optimized caching
                                    // Fix the optimized caching algorithm and then re-enable by deleting "await"
                                    _6.apply(_5, [_7.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _1.label = 52;
                case 52:
                    _1.trys.push([52, 57, 58, 59]);
                    _q = __values(Array.isArray(listed) ? listed : [listed]), _r = _q.next();
                    _1.label = 53;
                case 53:
                    if (!!_r.done) return [3 /*break*/, 56];
                    item = _r.value;
                    return [5 /*yield**/, _loop_2(item)];
                case 54:
                    _1.sent();
                    _1.label = 55;
                case 55:
                    _r = _q.next();
                    return [3 /*break*/, 53];
                case 56: return [3 /*break*/, 59];
                case 57:
                    e_7_1 = _1.sent();
                    e_7 = { error: e_7_1 };
                    return [3 /*break*/, 59];
                case 58:
                    try {
                        if (_r && !_r.done && (_y = _q.return)) _y.call(_q);
                    }
                    finally { if (e_7) throw e_7.error; }
                    return [7 /*endfinally*/];
                case 59: return [3 /*break*/, 61];
                case 60:
                    promises = listed.map(function (item) { return [item, item]; });
                    _1.label = 61;
                case 61: return [4 /*yield*/, Promise.all(promises)];
                case 62:
                    result = (_1.sent())
                        .sort(function (a, b) { return (a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0); })
                        .map(function (_a) {
                        var _b = __read(_a, 2), item = _b[0], sortValue = _b[1];
                        return item;
                    });
                    return [3 /*break*/, 73];
                case 63:
                    if (!functions[expression[0]]) return [3 /*break*/, 72];
                    func = functions[expression[0]];
                    params = [];
                    _1.label = 64;
                case 64:
                    _1.trys.push([64, 69, 70, 71]);
                    _s = __values(expression.slice(1)), _t = _s.next();
                    _1.label = 65;
                case 65:
                    if (!!_t.done) return [3 /*break*/, 68];
                    param = _t.value;
                    _v = (_u = params).push;
                    return [4 /*yield*/, keval(currentSource, sourceProvider, param, isStrict)];
                case 66:
                    _v.apply(_u, [_1.sent()]);
                    _1.label = 67;
                case 67:
                    _t = _s.next();
                    return [3 /*break*/, 65];
                case 68: return [3 /*break*/, 71];
                case 69:
                    e_8_1 = _1.sent();
                    e_8 = { error: e_8_1 };
                    return [3 /*break*/, 71];
                case 70:
                    try {
                        if (_t && !_t.done && (_z = _s.return)) _z.call(_s);
                    }
                    finally { if (e_8) throw e_8.error; }
                    return [7 /*endfinally*/];
                case 71:
                    if (isValid(func, params)) {
                        result = func.eval.apply(func, __spreadArray([], __read(params), false));
                    }
                    else {
                        throw new Error("Invalid expression: " + JSON.stringify(expression));
                    }
                    return [3 /*break*/, 73];
                case 72:
                    if (!isStrict) {
                        result = expression;
                    }
                    else {
                        throw new Error("Invalid expression: " + JSON.stringify(expression));
                    }
                    _1.label = 73;
                case 73: return [3 /*break*/, 75];
                case 74:
                    if (expression === ".") {
                        result = currentSource;
                    }
                    else if (typeof expression === "string" && expression.startsWith(".")) {
                        // TODO: consolidate "get", "list", and "."
                        // have "." infer the resource if the field doesn't exist
                        // e.g. .partner => GET "srs:placements:/company/<.company>/partners/<.partnerId>"
                        result = extract(currentSource, expression);
                    }
                    else {
                        // raw value
                        result = expression;
                    }
                    _1.label = 75;
                case 75:
                    console.log(JSON.stringify(expression), "=>", JSON.stringify(result), "using", ((_0 = JSON.stringify(currentSource)) === null || _0 === void 0 ? void 0 : _0.slice(0, 100)) + "...", "\n");
                    return [2 /*return*/, result];
            }
        });
    });
};
export var getPartOrSection = function (doc, path) {
    var _a, _b, _c, _d, _e, _f;
    // TODO: allow for valueType === "section" && maxOccurrances == 1
    var part = doc;
    var pathParts = path.split(".");
    var _loop_3 = function () {
        var partKey = pathParts.shift();
        var nextPart = ((_b = (_a = part.parts) !== null && _a !== void 0 ? _a : part.values) !== null && _b !== void 0 ? _b : []).find(function (p) { var _a; return ((_a = p.partKey) !== null && _a !== void 0 ? _a : p.sectionKey) === partKey; });
        if (!nextPart &&
            part.valueType === "section" &&
            ((_d = (_c = part.typeConditions) === null || _c === void 0 ? void 0 : _c.maxOccurrances) !== null && _d !== void 0 ? _d : 1) === 1) {
            // skip descent of trivial sections
            part = part.values[0];
            nextPart = ((_f = (_e = part.parts) !== null && _e !== void 0 ? _e : part.values) !== null && _f !== void 0 ? _f : []).find(function (p) { var _a; return ((_a = p.partKey) !== null && _a !== void 0 ? _a : p.sectionKey) === partKey; });
        }
        if (!nextPart) {
            return { value: undefined };
        }
        part = nextPart;
    };
    while (pathParts.length > 0) {
        var state_1 = _loop_3();
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return part;
};
